@import "./bootstrap/scss/bootstrap.scss";
@import "./scss/website/LandingNavbar.scss";
@import "./scss/website/Landing.scss";
@import "./scss/website/Feature.scss";
@import "./scss/website/Login.scss";

@import "./scss/Content.scss";
@import "./scss/Dashboard.scss";
@import "./scss/Files.scss";
@import "./scss/Modal.scss";
@import "./scss/style.scss";
@import "./scss/Team.scss";
@import "./scss/ViewTask.scss";
@import "./scss//ShimmerEffect.scss";
@import "./scss/custom-calendar.scss";
@import "./scss/Responsive.scss";
@import "./index.css";
@import "./scss/Blog.scss";
@import "./scss/timeline.scss";

