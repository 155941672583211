.text-infor {
    font-size: 9px;
}

#right{
    width: 30%;
}

// view file style

.lebels{
    background:rgba($color: #FFAB00, $alpha:.2);
    border-radius: 6px;
    font-size: 10px;
    padding:6px;
    color:#FFAB00;
}

.p-50 {
    padding: 50px !important;
}
.custom-file-uploader {
    border: 2px dashed rgb(17, 17, 18) !important;
    min-width: 100% !important;
    max-width: 100% !important;
}
.custom-file-uploader svg {
    display: none !important;
}
  
.custom-file-uploader div span {
    text-decoration: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.custom-file-uploader .jWkLDY .file-types {
    display: none !important;
}
.csv-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
  