// common style
@mixin taskDescription {
  position: relative;
  width: -webkit-fill-available;
  color: #707070;
  font-size: 20px;
}

@mixin descriptionBorder {
  border: 3px dotted #ddd;
  border-radius: 10px;
  padding: 10px;
  overflow-wrap: anywhere !important;
}

.h-52 {
  height: 52px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.refreshBoard {
  background-image: url("../images/RefreshSprint.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.custom_select {
  border: none !important;
}

.f-10 {
  font-size: 10px !important;
}

.viewFile_container {
  padding: 15px 28px;
}

.add_file {
  width: fit-content;
  margin-left: auto;
}

.file_upload_date {
  color: #707070;
  font-size: 20px !important;
}

.mt-3_33 {
  margin-top: 3.35%;
}

.select-filter {

  //for status
  .css-q7d4m3-MuiPaper-root-MuiPopover-paper,
  .css-1e1pf8f {
    left: unset !important;
    right: 73px;
    width: 168px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    margin-top: 9px !important;
    padding: 0 !important;

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .css-1km1ehz {
      font-size: 14px !important;
      padding: 10px 16px 10px 16px !important;
    }
  }

  .css-q7d4m3-MuiPaper-root-MuiPopover-paper::before,
  .css-1e1pf8f::before {
    content: unset !important;
  }

  // for prioritys
  .css-xt4vyd-MuiPaper-root-MuiPopover-paper,
  .css-1ydevsv {
    left: unset !important;
    right: 73px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    margin-top: 5px !important;
    padding: 0 !important;
    width: 168px;

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .css-1km1ehz {
      font-size: 14px !important;
      padding: 10px 16px 10px 16px !important;
    }
  }

  .css-xt4vyd-MuiPaper-root-MuiPopover-paper::before,
  .css-1ydevsv::before {
    content: unset !important;
  }
}

.color-todo,
.color-noprogress {
  color: #707070 !important;
}

.color-inprogress {
  color: #ffab00 !important;
}

.color-testing {
  color: #ff7452 !important;
}

.color-completed,
.color-done {
  color: #28a745 !important;
}

.reply_comment {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5px 10px;
  background: #ffffff;
  border-radius: 0px 6px 6px 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  margin: 1.5%;
}

.user_comment {
  height: 36px;
  width: 36px;
  border-radius: 6px;
}

.highlighted-mention {
  background: #a0bfe7;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
}

.comment_text_area {
  min-height: 150px;
  overflow: hidden;

  .rdw-mention-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 6px 8px;
    border-radius: 6px;
  }
}

.task-prioritys {
  .view-task-priority {
    position: relative;

    svg {
      position: absolute;
      right: 9px;
    }
  }

  .none-priority,
  .low-priority,
  .hg-priority,
  .md-priority {
    font-size: 14px !important;
    width: 168px;
    height: 27px;
    // padding: 3px 23px 3px 15px;
    // padding: 5px 12px 5px 12px !important;
  }

  .none-priority,
  .low-priority,
  .hg-priority {
    svg {
      margin-left: 29px !important;
    }
  }
}

.task-due-date {
  font-size: 14px !important;
  width: 168px;
  height: 27px;

  .date {
    margin-top: unset !important;
  }

  .react-datepicker__input-container {
    padding: 3px !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 14px !important;
  }
}

.task-status {
  .custom_select_view {
    position: relative;
    padding: 4px 0px !important;
    text-align: center;

    svg {
      position: absolute;
      right: 10px;
      top: 9px;
    }
  }

  // .no-progress svg {
  //     margin-left: 30px !important;
  // }

  // .in-progress svg {
  //     margin-left: 36px !important;
  // }

  // .testing svg {
  //     margin-left: 59px !important;
  // }

  // .done {
  //     svg {
  //         margin-left: 75px !important;
  //     }
  // }

  // .completed-svg {
  //     svg {
  //         margin-left: 35px !important;
  //     }
  // }
  .status {
    cursor: pointer;
    width: 168px !important;
    height: 27px !important;
    font-size: 14px !important;
  }

  .testing {
    width: 168px !important;
    height: 27px !important;
    font-size: 14px !important;
    padding: 4px 0px 3px 28px !important;
    cursor: pointer;
  }

  .no-progress,
  .in-progress,
  .done {
    cursor: pointer;
    width: 168px !important;
    height: 27px !important;
    font-size: 14px !important;
  }
}

.selected-labels {
  display: flex;
  flex-wrap: wrap;

  svg {
    background: unset !important;
    font-size: 13px !important;
    color: #ffab00 !important;
  }

  .css-6od3lo-MuiChip-label {
    padding-left: 10px;
    padding-right: 10px;
  }

  .css-38raov-MuiButtonBase-root-MuiChip-root,
  .css-o1spff {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    background-color: #fff7e6 !important;
    background: #fff7e6 !important;
    border-radius: 6px !important;
    color: #ffab00 !important;
    font-size: 14px !important;
  }

  .css-38raov-MuiButtonBase-root-MuiChip-root {
    height: 25px;
  }
}

.rdw-suggestion-dropdown {
  position: static !important;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  min-width: 300px;
  max-height: 200px !important;
  overflow: auto;
  background: white;
  z-index: 100;
  cursor: pointer;
}

.comment_editor {
  min-height: 400px !important;
}

.editor_text_height {
  height: 200px;
}

.margin_top_2 {
  margin-top: 2%;
}

.w-7px {
  width: 7px;
}

.add_bg {
  background-color: rgb(245, 245, 245) !important;
}

.low-priority-color {
  color: #2684ff !important;
}

.none-priority-color {
  color: #858581 !important;
}

.hg-priority-color {
  color: #ff7452 !important;
}

.md-priority-color {
  color: #ffab00 !important;
}

.reply_to_user {
  background: #eee;
  border-left: 3px solid #0052cc;
  width: fit-content;
  border-radius: 0 6px 6px 6px;

  .reply_to_quote {
    padding: 10px 20px 10px 10px;
    font-style: italic;

    .user_comment {
      height: 24px;
      width: 24px;
      border-radius: 6px;
    }
  }
}

.show_more_comment {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show_more {
    cursor: pointer;
    color: #0052cc;
  }

  .horizontal-line {
    border-top: 1px solid rgba(157, 166, 186, 0.7);
    display: inline-block;
  }

  .w-34 {
    width: 34%;
  }

  .w-32 {
    width: 32%;
  }
}

.comment {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  padding: 10px;

  .time_ago {
    color: rgba(157, 166, 186, 0.7);
    font-size: 12px;
    margin-top: 2%;
  }

  .user_name {
    margin-left: 12px;
  }

  .task_comment_message {
    margin-bottom: 0px !important;

    p {
      margin-bottom: 0px !important;
    }
  }
}

.file_main_section {
  padding: 7px;
  // height: 160px;
  // width: 116px;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 10px;
  position: relative;
  padding-bottom: 16px;

  p {
    margin-bottom: 0;
  }

  .file_main_section_left {
    padding-left: 50px;
  }

  .mic-vid-icon {
    position: absolute;
    left: 10px;
    top: 20%;
  }

  .file_top_section {
    height: 116px;
    width: 116px;
    cursor: pointer;
    display: flex;

    .other_file_image {
      height: 65px;
      margin: auto;
    }
  }

  .delete_file {
    background: #eee;
    position: relative;
    left: 82%;
    top: 3%;
    padding: 3px 4px;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
  }

  .time {
    font-size: 14px;
  }
}

.file_bottom_section {
  height: 25px;
  cursor: pointer;

  p {
    font-size: 8px;
    // line-height: 8px;
  }

  .file_name {
    max-width: 60px;
    margin-top: 10px;
  }

  .file_size {
    margin-top: -7px;
  }
}

.react_calender {
  position: absolute;
  width: 75%;
  right: 2%;
}

.mw_130 {
  max-width: 130px;
  cursor: pointer;
}

.task_title {
  max-width: 92%;
}

.active_task {
  color: blue;
}

.task_count {
  padding: 5px 8px;
  background: #0052cc;
  color: #ffffff;
  border-radius: 50px;
  font-size: 12px;
}

.task_progress {
  width: 23%;
  padding: 5px 12px;
  border-radius: 6px;
  font-size: 11px;
}

.textEllipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap;
}

.task_labels {
  margin-left: 40px;

  .labels {
    background: rgb(255, 247, 230);
    color: #ffab00;
    border-radius: 4px;
    padding: 4px;
    font-size: 10px;
    margin: 0px 10px 0px 0px;
  }
}

.add_follower {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background: #0052cc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_label {
  cursor: pointer;
  position: relative;
  left: 34%;
}

.custom_select_view {
  padding: 5px 12px 5px 12px;
  font-size: 12px;
  border-radius: 6px;
}

.mains {
  overflow: hidden;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 10px;
  width: 116px;
  height: 156px;

  .imgs {
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;

    .selectedFiles {
      height: 115px;
      width: 116px;
      border-radius: 10px 10px 0px 0px;
      margin-left: 21px;
    }

    .fileDeselect {
      position: relative;
      top: -45px;
      right: 25px;
      font-size: 10px;
      background: #eee;
      padding: 4px 6px;
      border-radius: 50px;
      cursor: pointer;
    }
  }

  .file-text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown {
      margin-right: 0.5rem;

      .option {
        border-radius: 5px;
        width: 24px;
        display: flex;
        height: 24px;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
        }

        svg {
          color: #707070;
          font-size: 12px;
          margin: 0;
        }

        &:hover {
          background: #e5edfa;
          color: #0052cc;

          svg {
            color: #0052cc;
          }
        }

        .dropdown-menu {
          box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
          border-radius: 10px;
          border: none !important;
          min-width: 7rem;
          inset: 0px 0px auto auto !important;
          top: 29px !important;
          transform: none !important;

          a {
            font-size: 14px;
            font-weight: 500;
            color: #707070;

            &:active {
              background: transparent;
            }
          }
        }
      }

      .show.option {
        background: #e5edfa;
        color: #0052cc;
        border-radius: 10px;
      }
    }

    .show.dropdown {
      .option {
        background: #e5edfa;
        color: #0052cc;

        svg {
          color: #0052cc;
        }
      }
    }
  }
}

.texts {
  height: 45px;
  margin-top: 5%;

  .filedisplay {
    position: relative;
  }

  .filedisplay1 {
    position: absolute;
    right: 5%;
    top: 15px;

    button {
      svg {
        font-size: 14px;
      }

      width: 100%;
    }

    .show.dropdown {
      .option-icon {
        background: #e5edfa;
      }

      button {
        svg {
          color: #0052cc;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
      border-radius: 10px;
      border: none !important;
      min-width: 7rem;
      inset: 0px 0px auto auto !important;
      top: 28px !important;
      transform: none !important;

      a {
        font-size: 14px;
        font-weight: 500;
        color: #707070;

        &:active {
          background: transparent;
        }
      }
    }
  }

  .favs {
    font-size: 10px;
    margin-top: 17px;
    margin-left: 10px;
    cursor: pointer;
  }

  .files {
    margin-top: -10px;
    max-width: 60px;
  }

  p {
    font-size: 8px;
  }

  .user-div {
    display: flex;
    align-items: center;
    height: 16px;
    margin-top: -9px;

    .file_user {
      width: 12px;
      height: 12px;
      border-radius: 4px;
    }
  }
}

.file_main_section_div {
  height: fit-content !important;
  cursor: pointer;
  width: 190px;
  padding: 9px 0px 5px;

  .file_top_section {
    height: 120px;
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
      height: 120px;
    }
  }

  .file_main_bottom_section {
    padding: 2px;
    font-weight: 500;
    font-size: 12px;

    p {
      line-height: 15px;
    }

    .file_size {
      color: #707070;
    }

    .file_name {
      height: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file_origin {
        background-repeat: no-repeat;
        background-size: cover;
        height: 20px;
        width: 20px;
        border-radius: 4px;
      }

      .project_icon {
        background-image: url("../images/project.png");
      }

      .task_icon {
        background-image: url("../images/tasks.svg");
      }

      .subtask_icon {
        background-image: url("../images/task-yellow.svg");
      }

      .bug_icon {
        background-image: url("../images/Bug.png");
      }

      .comment_icon {
        background-image: url("../images/infinity.svg");
      }

      // .file {
      //     margin-top: 15px;
      // }
    }

    .origin_div {
      justify-content: start !important;
      margin-top: 5px;

      .task_name {
        // margin-top: 16px;
        max-width: 110px;
      }
    }

    .user-div {
      display: flex;
      align-items: center;
      height: 25px;
      margin-top: 7px;

      .file_user {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }

      .created_by {
        color: #0052cc;
        max-width: 67%;
      }
    }
  }
}

// right content
#rights {
  width: auto;

  margin-top: -7%;

  .boder {
    border: 1px solid #0052cc;
  }
}

// top content

.content-top {
  .view-task.heading.w-80 {
    width: 80% !important;
  }

  .main-description {
    // border: 1px solid #ddd;
    // border-radius: 10px;
    // padding: 10px;
    margin-top: 14px;
  }

  .desc-b {
    @include descriptionBorder();

    span {
      font-size: 14px;
    }
  }

  .text-div {
    .description-preview * {
      // font-family: "Poppins", sans-serif !important;
    }
  }

  .edit__wiki__box_2 {
    border: 1px solid #707070;
    padding: 15px;
    border-radius: 10px;
    height: 375px;
    overflow-y: auto;

    .editor-class .notranslate span {
      color: #000000;
    }

    .editor-class .notranslate * {
      // font-family: "Poppins", sans-serif !important;
    }
  }

  .edit__wiki__box_2.text-task {
    border: 3px dotted #ddd;
    padding: 10px !important;

    .rdw-editor-toolbar {
      padding: 0;
      margin-bottom: 0px;
      border-bottom: 1px solid #ddd !important;
    }

    .rdw-editor-main {
      padding-left: 5px;
      overflow: hidden;
    }
  }

  .paragraph {
    max-width: 580px;
    max-height: 200px;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 1.4;
    margin-top: 2%;

    &:hover {
      .action_cursor {
        display: block;
      }
    }
  }

  .action_cursor_head {
    cursor: pointer;
    margin-left: 17%;
  }

  .hover_head {
    cursor: pointer;

    svg {
      position: absolute;
      right: 31.5%;
      cursor: pointer;
    }
  }

  .description_head {
    margin-top: 2%;
    margin-bottom: 2%;

    .action_cursor {
      position: absolute;
      cursor: pointer;
      right: 31.5% !important;
    }

    .desc_heading {
      color: #707070;
      font-size: 20px;
    }

    .see_less {
      color: #0052cc;
      font-size: 10px;
      font-weight: 500;
      margin-left: 2%;
      cursor: pointer;
    }
  }

  .description_control_btn {
    float: right;
    margin-bottom: 1%;
    margin-top: 3%;
  }

  .test-case-desc-ctrl {
    float: unset !important;
  }
}

.m-content {
  #bx {
    background: #fff;

    .zack {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        cursor: pointer;
      }
    }

    .barder {
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      width: 75%;

      .linked {
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #0052cc;
          width: 100%;
        }
      }
    }

    .buton {
      font-weight: 600;
      padding: 8px 12px;
    }

    #items {
      box-shadow: 0px 1px 4px #0000001a;
      width: 100%;

      .action {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .show.dropdown {
          background: #e5edfa;
          color: #0052cc;
          border-radius: 6px;

          svg {
            color: #0052cc;
          }
        }

        .option-icon {
          border-radius: 6px;

          &:hover {
            background: #e5edfa;
            color: #0052cc;

            svg {
              color: #0052cc;
            }
          }

          svg {
            height: 12px;
            color: #707070;
          }

          a {
            color: #707070;
            font-size: 14px;
            font-weight: 500;

            &:active {
              background: unset !important;
              color: #707070 !important;
            }
          }
        }
      }

      .dropdown-menu {
        min-width: 7rem;
      }

      .dropdown-menu.show {
        top: 30px !important;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
        border-radius: 10px !important;
        border: none !important;
        min-width: 7rem;
        transform: translate(0px) !important;
        height: fit-content;
      }
    }

    .controls {
      box-shadow: 0px 1px 4px #0000001a;
      border-radius: 10px;
    }

    .cusor {
      cursor: pointer;
    }

    .user-div {
      display: flex;
      align-items: center;
    }
  }
}

.routes {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  width: 350px;
  padding: 20px 20px;
  position: absolute;
  // left: 72%;
  right: 34px;
  // top: 24%;
  top: 65px;

  .unassigned_image {
    img {
      margin-top: 0px !important;
    }
  }

  .zacks {
    font-weight: 600;

    .Form.Select:focus,
    .Form.Select:active {
      outline: none !important;
      box-shadow: none !important;
    }

    .custom-dropdown-toggle {
      font-size: 1rem;
      font-weight: 600;

      .followerImage {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        margin-left: -15px;
      }
    }

    .custom-dropdown-item {
      display: flex;
      align-items: center;

      &:hover {
        background: none;
      }
    }

    .custom-dropdown-image {
      width: 25px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .custom-checkbox {
      margin-left: auto;
    }

    .newFeature {
      cursor: pointer;

      .avatar {
        height: 25px;
        width: 25px;
        font-size: 10px;
        background: #2c4a9e;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name-time {
        display: flex;
        flex-direction: column;

        .user-name {
          margin-top: -5px;
          margin-bottom: -7px;
        }

        .time {
          font-size: 10px;
          margin-left: 10px;
        }
      }
    }
  }

  .mt-3 {
    margin-top: 3px !important;
  }

  .hg-priority {
    background: #fff1ed;
    color: #ff7452;
    // padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
    // margin-top: -6px;
  }

  .md-priority {
    background: #fff6e5;
    color: #ffab00;
    // padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .low-priority {
    background: #e9f2ff;
    color: #2684ff;
    // padding: 5px 23px 5px 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
  }

  .none-priority {
    background: #eee;
    color: #7a7a7a;
    // padding: 5px 23px 5px 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
  }

  .date {
    background: #e9f2ff;
    color: #2684ff;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    // margin-top: -6px;
    font-size: 12px;

    .react-datepicker__input-container {
      padding: 5px;

      svg {
        font-size: 14px;
      }
    }
  }

  .msgs {
    margin-top: -6px;
    background: #f4f5f7;
    color: #707070;
    padding: 5px 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    width: 46%;

    p {
      font-size: 12px;
    }
  }

  .drop-up {
    margin-top: -30%;
    padding: 15px 20px;
  }

  .drops {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
}

// description

.edit__wiki__box_2 {
  border: 1px solid #707070;
  padding: 15px;
  margin-top: 10px;
  border-radius: 10px;
}

.rdw-editor-toolbar {
  border: none !important;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0052cc !important;
  outline: none !important;
  color: #0052cc !important;
  height: 27px;
  padding: 0px;
}

.nav-tabs {
  border: none !important;
  outline: none !important;
}
.nav-tabs .viewTest-defect-text{
  display: flex;
  align-items: center;

  .defect-info_icon{
    font-size: 20px;
    margin-left: 5px;
  }
}
.nav-link {
  border: none !important;
  color: #707070 !important;
  padding: 0px;
  height: 27px;
  margin-right: 46px;
}

.nav_prob {
  border-top: 2px solid #f8f8fa;
  border-bottom: 2px solid #f8f8fa;
  height: 30px !important;
}

.border_less {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #0052cc;
  outline: none;

  &:focus {
    outline: none;
    border-bottom: 1px solid #0052cc;
  }
}

.footer-bottom {
  z-index: 1;
  background: #fff;
  // position: fixed;
  // bottom: 0;
  width: -webkit-fill-available;
  margin-left: -18px;
  margin-right: -18px;
}

.sub-task-view {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  width: 90%;
  padding: 20px 20px;
  position: absolute;
  left: 4%;
  top: 25%;

  .zacks {
    font-weight: 600;

    .Form.Select:focus,
    .Form.Select:active {
      outline: none !important;
      box-shadow: none !important;
    }

    .custom-dropdown-toggle {
      font-size: 1rem;
      font-weight: 600;

      .followerImage {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        margin-left: -15px;
      }
    }

    .custom-dropdown-item {
      display: flex;
      align-items: center;

      &:hover {
        background: none;
      }
    }

    .custom-dropdown-image {
      width: 25px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .custom-checkbox {
      margin-left: auto;
    }

    .newFeature {
      cursor: pointer;

      .avatar {
        height: 25px;
        width: 25px;
        font-size: 10px;
        background: #2c4a9e;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .hg-priority {
    background: #fff1ed;
    color: #ff7452;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
    margin-top: -6px;
  }

  .md-priority {
    background: #fff6e5;
    color: #ffab00;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .low-priority {
    background: #e9f2ff;
    color: #2684ff;
    padding: 5px 23px 5px 23px;
    // width: 123px;
    // height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .none-priority {
    background: #eee;
    color: #7a7a7a;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
  }

  .date {
    background: #e9f2ff;
    color: #2684ff;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    margin-top: -6px;
    font-size: 12px;
  }

  .msgs {
    margin-top: -6px;
    background: #f4f5f7;
    color: #707070;
    padding: 5px 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    width: 46%;

    p {
      font-size: 12px;
    }
  }

  .drop-up {
    margin-top: -30%;
    padding: 15px 20px;
  }

  .drops {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
}

.sub-task-des {
  .main-description {
    margin-top: 30%;
    margin-left: 20;
    margin-right: 20px;
  }

  .desc-b {
    @include descriptionBorder();

    span {
      font-size: 14px;
    }
  }

  .Description-box {
    .description-preview * {
      // font-family: "Poppins", sans-serif !important;
    }

    margin-left: 20px;
  }

  .edit__wiki__box_2 {
    border: 1px solid #707070;
    padding: 15px;
    border-radius: 10px;
    height: 375px;
    overflow-y: auto;

    .editor-class .notranslate span {
      color: #000000;
    }

    .editor-class .notranslate * {
      // font-family: "Poppins", sans-serif !important;
    }
  }

  .edit__wiki__box_2.text-task {
    border: 3px dotted #ddd;
    padding: 10px !important;
    margin-left: 20px;

    .rdw-editor-toolbar {
      padding: 0;
      margin-bottom: 0px;
      border-bottom: 1px solid #ddd !important;
    }

    .rdw-editor-main {
      padding-left: 5px;
      overflow: hidden;
    }
  }

  .paragraph {
    max-width: 580px;
    max-height: 200px;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 1.4;
    margin-top: 2%;

    &:hover {
      .action_cursor {
        display: block;
      }
    }
  }

  .action_cursor_head {
    cursor: pointer;
    margin-left: 17%;
  }

  .hover_head {
    cursor: pointer;
  }

  .description_head {
    margin-top: 2%;
    margin-bottom: 2%;

    .action_cursor {
      position: absolute;
      cursor: pointer;
      right: 31.5%;
    }

    .desc_heading {
      color: #707070;
      font-size: 20px;
      margin-left: 20px;
    }

    .see_less {
      color: #0052cc;
      font-size: 10px;
      font-weight: 500;
      margin-left: 2%;
      cursor: pointer;
    }
  }

  .description_control_btn {
    float: right;
    margin-bottom: 1%;
    margin-top: 3%;
  }

  .test-case-desc-ctrl {
    float: unset !important;
  }
}

.sub-task-sidebar {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  padding: 20px 20px;

  .zacks {
    font-weight: 600;

    .Form.Select:focus,
    .Form.Select:active {
      outline: none !important;
      box-shadow: none !important;
    }

    .custom-dropdown-toggle {
      font-size: 1rem;
      font-weight: 600;

      .followerImage {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        margin-left: -15px;
      }
    }

    .custom-dropdown-item {
      display: flex;
      align-items: center;

      &:hover {
        background: none;
      }
    }

    .custom-dropdown-image {
      width: 25px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .custom-checkbox {
      margin-left: auto;
    }

    .newFeature {
      cursor: pointer;

      .avatar {
        height: 25px;
        width: 25px;
        font-size: 10px;
        background: #2c4a9e;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .hg-priority {
    background: #fff1ed;
    color: #ff7452;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
    margin-top: -6px;
  }

  .md-priority {
    background: #fff6e5;
    color: #ffab00;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .low-priority {
    background: #e9f2ff;
    color: #2684ff;
    padding: 5px 23px 5px 23px;
    // width: 123px;
    // height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
  }

  .none-priority {
    background: #eee;
    color: #7a7a7a;
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
  }

  .date {
    background: #e9f2ff;
    color: #2684ff;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    margin-top: -6px;
    font-size: 12px;
  }

  .msgs {
    margin-top: -6px;
    background: #f4f5f7;
    color: #707070;
    padding: 5px 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    width: 46%;

    p {
      font-size: 12px;
    }
  }

  .drop-up {
    margin-top: -30%;
    padding: 15px 20px;
  }

  .drops {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
}

.sub-task-content-top {
  // .view-task.heading.w-80 {
  //     width: 100% !important;
  // }

  .main-description {
    // border: 1px solid #ddd;
    // border-radius: 10px;
    // padding: 10px;
    margin-top: 14px;
  }

  .desc-b {
    @include descriptionBorder();

    span {
      font-size: 14px;
    }
  }

  .text-div {
    .description-preview * {
      // font-family: "Poppins", sans-serif !important;
    }
  }

  .edit__wiki__box_2 {
    border: 1px solid #707070;
    padding: 15px;
    border-radius: 10px;
    height: 375px;
    overflow-y: auto;

    .editor-class .notranslate span {
      color: #000000;
    }

    .editor-class .notranslate * {
      // font-family: "Poppins", sans-serif !important;
    }

    .rdw-link-decorator-wrapper {
      .rdw-link-decorator-icon {
        position: unset;
        padding-bottom: 10px;
        padding-left: 5px;
      }
    }
  }

  .edit__wiki__box_2.text-task {
    border: 3px dotted #ddd;
    padding: 10px !important;

    .rdw-editor-toolbar {
      padding: 0;
      margin-bottom: 0px;
      border-bottom: 1px solid #ddd !important;
    }

    .rdw-editor-main {
      padding-left: 5px;
      overflow: hidden;
    }
  }

  .paragraph {
    max-width: 580px;
    max-height: 200px;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 1.4;
    margin-top: 2%;

    &:hover {
      .action_cursor {
        display: block;
      }
    }
  }

  .action_cursor_head {
    cursor: pointer;
    margin-left: 17%;

    &:hover {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }
  }

  .copy {
    color: #9b9b9b;
  }

  .hover_head {
    cursor: pointer;

    .text-capitalize.w_96 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .description_head {
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: space-between;

    .desc_heading {
      @include taskDescription();

      h4 {
        width: fit-content;
      }

      svg {
        position: absolute;
        right: 0;
        top: 9px;

        &:hover {
          filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
        }
      }
    }

    .desc-ai {
      display: flex;
      align-items: center;

      button {
        background: unset;
        color: #707070;
        border: unset;
        border-radius: unset;
        font-size: 14px;
        margin-right: 10px;
        display: flex;
        align-items: center;

        img {
          height: 18px;

          &:hover {
            filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
          }
        }

        span {
          font-size: 11px;
        }
      }

      .dropdown-menu.show {
        top: 26px !important;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
        border-radius: 10px !important;
        border: none !important;
        min-width: 7rem;
        transform: translate(-114px) !important;
        height: -moz-fit-content;
        height: fit-content;

        .dropdown-item {
          color: #707070;
          font-size: 14px;
          font-weight: 500;

          &:active {
            background: unset;
          }
        }
      }
    }

    .see_less {
      color: #0052cc;
      font-size: 10px;
      font-weight: 500;
      margin-left: 2%;
      cursor: pointer;
    }

    .fa-pencil:hover {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }
  }

  .description_control_btn {
    float: right;
    margin-bottom: 1%;
    margin-top: 3%;
  }

  .test-case-desc-ctrl {
    float: unset !important;
  }
}

.active_task_view_card {
  border: 1px solid blue;
}

.file_description {
  width: 80%;

  .desc_heading {
    @include taskDescription();
  }

  .desc-b {
    @include descriptionBorder();
    margin-top: 2%;
    margin-bottom: 3%;
  }
}

.file_by_user {
  height: 24px !important;
  width: 24px !important;
  border-radius: 6px;
}

.pl-20 {
  padding-left: 20px !important;
}

.align-right {
  text-align: right !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.subtask_assignee {
  width: unset !important;
  height: unset !important;
  padding: 0px 9.2px !important;
}

.view_task_icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px;
  margin-top: 6px;
}

.view_task_icon_File {
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px;
  margin-top: 1px;
}

.w_96 {
  width: 96%;
}

.project-files {
  padding: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  .mic-vid-icon {
    top: 0% !important;
    padding-top: 15px;

    .imgs {
      padding-left: 3px !important;
    }

    img {
      width: 40px;
      object-fit: cover;
    }
  }

  .file_main_section_left {
    padding-left: 60px;
  }

  .file-bottom {
    margin-right: 7px;
    margin-top: 4px;
  }

  .view_task_icon_File {
    width: 16px !important;
    height: 16px !important;
  }
}

.active-notification {
  .button_style {
    background: #e5edfa !important;

    svg {
      color: #0052cc !important;
    }
  }
}

.editable_heading {
  outline: none !important;
  border: none !important;
  display: inline;
  cursor: text !important;
}