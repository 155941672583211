.team__content__wrapper {
  padding-bottom: 100px;

  .team_member_img {
    text-decoration: none;
  }

  .team__content__wrapper__member {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843) !important;
    border-radius: 10px;
    width: 100%;
    padding: 15px 15px 12px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 160px;

    img {
      width: 100%;
      height: 100px;
      border-radius: 10px;
    }

    .team_content_para {
      color: #707070;
      font-size: 10px;
      margin-bottom: 0px;
    }

    .member_delete_icon {
      font-size: 14px;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        color: #0052cc;
      }
    }

    .team_member_name {
      color: #111;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: #0052cc;
      }
    }

    .card-user {
      width: 24px;
      height: 21px;
      border-radius: 6px;
      border: 1px solid #fff;
      margin-left: -15px;
      cursor: pointer;
    }

    .add-back {
      background: #0052cc;
      width: 24px;
      height: 21px;
      border-radius: 6px;
      border: 1px solid #fff;
      margin-left: -15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .add-icon {
        width: 9px;
        height: 9px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.date_wikies {
  img {
    margin-right: 6px;
    height: 15px;
    width: 15px;
  }
}

// .team__content__wrapper__member {
.users {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 942px) {
    // width: 100%;
    margin-top: 10px;
  }

  @media (max-width: 715px) {
    margin-left: 16px;
    // width: unset;
  }

  @media (max-width: 499px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-profile {
    width: 24px;
    height: 21px;
    border-radius: 6px;
    border: 1px solid #fff;
    margin-left: -15px;
    cursor: pointer;
  }
}

// }

// member  view detail page

.member__view_detail {
  padding-top: 16px;
  padding-bottom: 16px;

  .member__profile {
    // height: 565px;
    box-shadow: 1px 0px 4px #0000000d;
    border-radius: 10px;
    padding: 23px 23px 23px 23px;

    img {
      height: 100px;
      width: 100px;
    }

    .btn-conversation {
      background: #f4f5f7;
      border-radius: 10px;
      font-weight: bold;
      font-size: 13px;
      padding: 6px 10px 6px 10px;
    }

    .option {
      width: 30px;
      height: 33px;
      background: #e5edfa;
      border-radius: 6px;
      padding-top: 7px;
      color: #0052cc;
      cursor: pointer;

      .nav-select .dropdown-menu.show {
        top: 0;
      }
    }

    .padd_pro_btn {
      padding: 6px 27px 6px 27px;
    }
  }

  .resent__work {
    .member__project {
      border: 1px solid #f4f5f7;
      border-radius: 10px;
      padding: 10px;

      .show__status {
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.member__project_2 {
  border: 1px solid #f4f5f7;
  border-radius: 10px;
  padding: 10px;

  .show__status {
    align-items: center;

    img {
      margin-right: 10px;
    }

    .colligue_img {
      width: 26px;
      height: 24px;
      border-radius: 6px;
      border: 1px solid #979797;
    }
  }
}

// img {
//     // height: 2.6%;
//     // width: 2.6%;
//     margin-right: 10px;
// }

.font-weight__600 {
  font-weight: 600;
}

.ft-8 {
  font-size: 8px !important;
}

.ft-16 {
  font-size: 16px !important;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-20 {
  font-size: 20px !important;
}

.color__007 {
  color: #707070 !important;
}

.cam__hover {
  position: relative;

  &:hover .ext_hover {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ext_hover {
    position: absolute;
    height: 100px;
    width: 100px;
    background: #5e7594;
    border-radius: 10px;
    opacity: 0.75;
    top: 0;
    left: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;

    .fa-camera {
      color: #fff !important;
      opacity: 1;
    }
  }
}

.member__in_profile {
  .show__status {
    display: flex;
    align-items: center;

    img {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }
}

// conversation section start

.conversation__wrapper {
  display: flex;

  .conversation__sidebar {
    width: 20%;
    background: #f4f5f7;
    overflow-y: auto;
    padding: 25px;
    position: fixed;
    height: 100%;

    @media (max-width: 768px) {
      padding: 13px;
      width: 11%;
    }
  }

  .conversation__content {
    width: 75%;
    margin-left: 25%;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 89%;
      margin-left: 7%;
    }

    .conversation__topbar {
      position: fixed;
      border-bottom: 1px solid #f4f4f4;
      padding: 12px 30px;
      width: 60vw;
      background: #fff;
      margin-top: -2px;

      // width: 86%;
      // margin-left: 14%;
      @media (max-width: 768px) {
        width: 76vw;
        margin-left: 5%;
      }

      @media (max-width: 768px) {
        padding-left: 12px;
        // width: 74vw;
      }

      .users {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        @media (max-width: 942px) {
          // width: 100%;
          margin-top: 10px;
        }

        @media (max-width: 715px) {
          margin-left: 16px;
          // width: unset;
        }

        @media (max-width: 499px) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .card-user {
          width: 24px;
          height: 24px;
          // border-radius: 6px;
          // border: 1px solid #fff;
          margin-left: -15px;
          cursor: pointer;
        }

        .user-profile {
          width: 24px;
          height: 24px;
          border-radius: 6px;
          border: 1px solid #fff;
          margin-left: -15px;
          cursor: pointer;
        }

        .add-back {
          width: 24px;
          height: 24px;
        }
      }
    }

    .chat__convaersation {
      margin-top: 70px;
      padding: 0px 50px 0px 50px;
      padding-bottom: 100px;

      @media (max-width: 768px) {
        // padding: 0px 50px 0px 50px;
        padding-right: 0px;
      }

      @media (max-width: 525px) {
        padding: 0px 50px 0px 36px;
      }

      .chat__file {
        width: 292px;
        padding: 8px 8px 4px 8px;
        background: #f4f5f7;
        border: 1px solid #f4f5f7;
        border-radius: 10px;
        margin-left: 32px;

        img {
          padding: 10px 10px 10px 10px;
          background: #dfe9f8;
          border-radius: 10px;
          color: red;
        }
      }

      .send__description {
        box-shadow: 0px 0px 4px #0052cc5c;
        border-radius: 10px;
        font-size: 14x;
        padding: 10px 10px 10px 10px !important;
        position: fixed;
        bottom: -48px;
        width: 52vw;

        @media (max-width: 768px) {}
      }

      .option {
        i {
          cursor: pointer;
        }

        .nav-select .dropdown-menu.show {
          top: 0;
          left: 0;
        }
      }

      .emoji__drop {
        background: #0052cc;
        color: #cfd1d2;
        margin-top: -10px;

        i {
          cursor: pointer;
        }
      }
    }
  }
}

.ml-15 {
  margin-left: 3px !important;
}

.display__none_ever {
  transition: opacity 0.3s !important;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.prob_drowpdown {
  margin-left: -92px !important;
  min-width: 7rem !important;
}

// edit wiki page start

.edit_wiki_content {
  .wiki__btn__toggle {
    height: 36px;
    padding: 0px 14px !important;

    &:hover {
      // background: none !important;
      color: #fff !important;
    }

    .angle-d {
      border-left: 1px solid #fff;
      margin-left: 12px;
      padding-left: 14px;
    }
  }

  .lock__icon {
    padding: 11px 12px !important;
    background: #f4f5f7 !important;
    border-radius: 10px;
  }

  .btn_close {
    padding: 0px !important;
    border: none !important;
  }

  .edit__wiki__box {
    border: 1px solid #ced4da;
    padding: 15px;
    height: 330px;
    border-radius: 10px;
  }

  .edit__wiki__box_2 {
    border: 1px solid #ced4da;
    padding: 15px;
    border-radius: 10px;
  }

  .rdw-editor-toolbar {
    border: none !important;
  }

  .nav-tabs .nav-link.active {
    border-bottom: 2px solid #0052cc !important;
    outline: none !important;
    color: #0052cc !important;
    height: 27px;
    padding: 0px;
  }

  .nav-tabs {
    border: none !important;
    outline: none !important;
  }

  .nav-link {
    border: none !important;
    color: #707070 !important;
    padding: 0px;
    height: 27px;
    margin-right: 46px;
  }

  .nav_prob {
    border-top: 2px solid #f8f8fa;
    border-bottom: 2px solid #f8f8fa;
    height: 30px !important;
  }
}

// view wiki page start

.view__wiki__page {
  .option {
    width: 30px;
    height: 33px;
    background: #e5edfa;
    border-radius: 6px;
    padding-top: 6px;
    color: #0052cc;
    cursor: pointer;
    text-align: center;

    .nav-select .dropdown-menu.show {
      top: 0;
    }
  }

  .top-right_items button {
    border: 1px solid #0052cc;
  }

  .content-top {
    .span__margin_none {
      margin-left: 0px !important;
    }

    .top-right_items {
      height: fit-content;
      justify-content: unset;
      // width: 42%;
      width: unset;
      margin-top: 0px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .top-right_items-wiki {

      .member__view_detail,
      .lock__icon,
      .btn-cencal,
      .users {
        margin-left: 10px;
      }
    }
  }

  .export-item {
    display: flex;

    .dropdown-menu {
      min-width: 8rem;
      right: 160px;
      top: -26px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
      border-radius: 10px;
    }

    &:hover {

      .export-dropdown,
      .export-menu {
        display: block !important;
      }
    }

    span {
      background-image: url("../images/arrow-right.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 5px;
      height: 9px;
      display: flex;
      margin-left: auto;
      margin-top: 5px;
    }
  }
}

.border_less {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #0052cc;
  outline: none;

  &:focus {
    outline: none;
    border-bottom: 1px solid #0052cc;
  }
}

.color_gray {
  color: #707070 !important;
}

.view__wiki__content {
  .container__ever {
    width: 83%;
    margin-left: auto;
    margin-right: auto;
  }
}

.border_radius10 {
  border-radius: 10px;
}

.size_cover {
  background-size: cover;
  background-position: center;
}

.img_height {
  height: 100px !important;
  width: 130px !important;
  margin: auto;
  cursor: pointer;
}

.profile_img_height {
  height: 64px !important;
  width: 64px !important;
  margin: auto;
}

.mt-80 {
  margin-top: 80% !important;
}

@media (min-width: 768px) {
  .container__ever {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container__ever {
    width: 892px;
  }
}

@media (min-width: 1200px) {
  .container__ever {
    width: 1170px;
  }
}

.member-select {
  span {
    padding-left: 10px;
  }
}

// project page start

.modal__td {
  width: 30%;
}

.border_less {
  width: 90%;
}

.select-options {
  width: 24%;
  position: absolute;
  background: #fff;
}

.loop__img {
  .loop__img__user {
    width: 24px;
    height: 24px;
    border: 1px solid #ffffff;
    background-color: #989b9e;
    border-radius: 6px;
    margin-left: -6px;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  }
}

.user_image {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  margin: auto;
}

.profile_img_edit {
  height: 100px;
  width: 123px;
  border-radius: 10px;
  cursor: pointer;
}

.password_inputs {
  border: 1px solid #9b9d9e;
  border-radius: 10px;
  padding-right: 10px;

  input {
    border: none;
  }

  .eye-icon {
    color: #9b9d9e;
  }
}

.addCursorClass {
  cursor: pointer;
}

.team_content_top {
  @media (max-width: 942px) {
    margin-top: 10%;
  }

  @media (max-width: 768px) {
    margin-top: 10%;
  }

  @media (max-width: 525px) {
    margin-top: 32%;
  }
}

.team_member_rows {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  .member_img_loader {
    height: 130px;
    width: 130px;
  }

  .member_detail_load {
    height: 15%;
  }

  .team_role_load {
    width: 40%;
  }

  .member_removeIcon_load {
    width: 20%;
  }

  .member_name_load {
    width: 80%;
  }

  @media (max-width: 942px) {
    gap: 30px;
  }

  @media (min-width: 1600px) {
    gap: 27px;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 525px) {
    gap: 5% 20%;
  }
}

.team_people_heading {
  @media (max-width: 942px) {}

  @media (max-width: 768px) {
    font-size: 25px;
  }

  @media (max-width: 525px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
}

.view_file_name_container {
  margin: 10px 0px 22px;
  display: flex;
  align-items: center;
}

.view_file_small {
  height: 55px;
  width: 65px;
  // border-radius: 10px;
  // margin-top: 2.1%;
  // margin-bottom: 2.4%;
  cursor: pointer;

  img {
    height: 100%;
  }
}

.view_file_cta {
  border-radius: 10px !important;
  padding: 6px 10px !important;
  height: unset;
  width: unset;
  background: #f4f5f7;
}

.file_source {
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.task_icon {
  background-image: url("../images/tasks.svg");
}

// .background__even {
//   background: #e3e3e3 !important;
// }