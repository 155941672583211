// common styles

.ul-lis {
  margin-left: 5%;
  font-weight: 600;

  @media (min-width: 1380px) {
    margin-left: 14%;
  }
}

.titles {
  font-size: 48px !important;
}

.bugs {
  @media (max-width: 992px) {
    height: 100%;
  }
}

.sign-in {
  width: 100px;
  height: 48px;
  margin-right: -2px;

  @media (max-width: 992px) {
    border: none !important;
    padding: 5px 0px 8px 0px !important;
    color: #707070 !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    background: none !important;
    margin-left: 0px !important;
    height: unset !important;

    &:hover {
      color: #0052cc !important;
    }
  }
}

.btn__signup {
  width: 100px;
  height: 48px;
  margin-left: 16px;

  @media (max-width: 992px) {
    border: none !important;
    padding-bottom: 0px !important;
    color: #707070 !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin-left: 0px !important;
    background: none !important;
    height: unset !important;

    &:hover {
      color: #0052cc !important;
    }
  }
}

.t-hed {
  font-size: 24px !important;
}

.t-bodies {
  font-weight: 600;
  font-size: 18px;
}

.t-pas {
  padding-left: 30px !important;
}

.bg {
  height: 88vh !important;
}

#bg-p {
  height: calc(100vh - 64px);

  @media (min-width: 1380px) {
    height: calc(100vh - 390px);
  }

  @media (max-width: 992px) {
    height: calc(100vh - -195px);
  }
}

.linear-bg {
  background: linear-gradient(119deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(183, 211, 254, 0.26094187675070024) 50%,
      rgba(255, 255, 255, 1) 95%);
}

.fix-buton {
  width: 150px;
  height: 45px;
}

.paragra {
  color: #707070;
}

.clor {
  background: #fde7ea !important;
}

.colrd {
  background: #e9f6ec !important;
}

.ad-mrgin {
  margin-left: -3px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.home_page_component {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);
  height: 100%;
  padding-bottom: 82px;

  @media (min-height: 810px) {
    padding-bottom: 124px;
  }

  @media (max-width: 992px) {
    padding-bottom: 60px;

    .landing_page_row {
      // flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      .col-md-6 {
        width: 100%;
      }

      .contant_section {
        width: 100%;
      }

      .image_section {
        width: 100%;
      }
    }
  }

  .home_Container {
    padding-top: 60px;
  }
}

// About Us page css

.aboutus_page {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);

  @media (max-width: 992px) {
    padding-top: 35px !important;
    padding-bottom: 25px !important;
  }

  background: linear-gradient(119deg,
    rgb(255, 255, 255) 5%,
    rgba(183, 211, 254, 0.2609418768) 50%,
    rgb(255, 255, 255) 95%);
  height: 100%;
  padding-bottom: 222px;

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }

  .top-text {
    margin-top: 6%;
  }
}

.about_us {
  padding-bottom: 20px;
}

.about-content {
  padding-top: 100px;

  .big-para {
    font-size: 20px;
    color: #707070;
  }

  .short-para {
    margin-top: 27px;
    padding-bottom: 7px;
  }

  .cad-paras {
    .card-title {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      color: #000000;
    }
  }

  .ad-margin {
    margin-left: 29px;

    @media (min-width: 1380px) {
      margin-left: 50px;
    }

    @media (max-width: 992px) {
      margin-left: 5px;
    }

    // @media (max-width: 540px) {
    //     text-align: center !important;
    //     width: 95% !important;
    //     align-items: center;
    //     justify-content: center;
    // }

    .zack {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 3px 14px #0052cc24;

      img {
        font-size: 18px;
        filter: invert(0%);
      }

      &:hover {
        background: #0052cc;

        img {
          filter: invert(100%);
        }
      }
    }
  }
}

.about_us_section {
  // padding: 40px;
  // overflow: hidden;
  width: 100%;
  // margin-left: auto;
  // margin-right: auto;
  margin-top: 5%;
  margin-bottom: 7%;


  // .cust {

  //   .choose_us_section {
  //     width: auto;
  //   }
  // }

  .row {
    margin-right: unset !important;

    .big_circle_box {
      position: absolute;
      margin-top: 25px;

      @media (max-width:800px) {
        text-align: center;
        position: unset;
        margin-top: unset;
        // padding: 10px 0px 10px 14px;

      }

      .big-circle {
        width: 60rem;
        background: #e5edfa;
        height: 100rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 100%;
        position: relative;
        left: -59%;
        padding-right: 20%;

        @media (max-width: 1366px) {
          width: 46rem;
        }

        @media (max-width: 1250px) {
          width: 39rem;
        }

        @media (max-width: 1050px) {
          width: 30rem;
        }

        @media (max-width: 800px) {
          width: 100% !important;
          background: #e5edfa;
          display: flex;
          height: 185px;
          margin-bottom: 20px;
          justify-content: center;
          align-items: center;
          position: relative;
          left: unset;
          border-radius: unset;
          padding-right: unset;
        }

        .why-text {
          width: 12%;

          @media (max-width: 1264px) {
            width: 12%;
          }

          @media (max-width: 1066px) {
            width: 19%;
          }

          @media (max-width: 800px) {
            width: 100%;
          }

          // @media (max-width: 799px) {
          //   width: 100%;
          // }
        }

        // @media (min-width: 1400px) {
        //   margin-left: -60%;
        // }

        // @media (min-width: 1440px) {
        //   margin-left: -55%;
        // }

        // @media (min-width: 1540px) {
        //   margin-left: -49%;
        // }

        // @media (min-width: 1710px) {
        //   margin-left: -40%;
        // }

        // @media (min-width: 2000px) {
        //   // margin-left: -30%;
        // }

        // @media (max-width: 1300px) {
        //   // width: 850px;
        // }

        // @media (max-width: 992px) {
        //   // width: 800px;
        //   // margin-left: -57%;
        //   // height: 830px !important;
        // }

        // @media (max-width: 510px) {
        //   // width: 323px;
        //   // margin-left: -57%;
        //   // height: 830px !important;
        // }

        // @media (max-width: 1300px) {
        //   // height: 1200px;
        //   // width: 999px;
        // }

        // .why-text {
        //   position: absolute;
        //   left: 5%;
        //   font-size: 64px;
        //   line-height: 75px;

        //   @media (min-width: 1300px) {
        //     left: 6%;
        //     font-size: 55px;
        //   }

        //   @media (min-width: 1400px) {
        //     left: 6%;
        //     font-size: 60px;
        //   }

        //   @media (min-width: 1440px) {
        //     left: 6%;
        //     font-size: 60px;
        //   }

        //   @media (min-width: 1540px) {
        //     left: 6%;
        //     font-size: 60px;
        //   }

        //   @media (max-width: 768px) {
        //     font-size: 20px;
        //     line-height: 32px;
        //     left: 3%;
        //     font-size: 40px;
        //     line-height: 50px;
        //   }

        //   // @media (max-width: 510px) {
        //   //   font-size: 20px;
        //   //   line-height: 32px;
        //   // }
        // }
      }
    }


    .choose_us_section {
      padding-right: 20%;

      @media (max-width: 1250px) {
        padding-right: unset !important;
      }

      @media (max-width: 800px) {
        padding: 20px !important;
      }

      .english-cards {
        width: 100%;
        display: flex;
        gap: 20px;
        margin: 30px;

        // @media (max-width: 510px) {
        //   margin: 15px !important;
        // }

        .alphabit_cards {
          padding: 50px 60px;
          text-align: center;
          box-shadow: 0px 1px 4px #0052cc1a;
          border-radius: 4px;
          display: flex;
          align-items: center;

          @media (max-width: 800px) {
            padding: 20px 40px;
          }

          // @media (max-width: 510px) {
          //   // width: 30px;
          //   margin-right: 6px;
          // }

          h1 {
            font-size: 70px;
            color: transparent linear-gradient(180deg,
                #0369bd 0%,
                var(--unnamed-color-0052cc) 100%) 0% 0% no-repeat padding-box;

            // @media (max-width: 510px) {
            //   font-size: 30px;
            // }
          }
        }

        .cads {
          // height: 180px;
          width: 100%;
          border-radius: 5px;
          // margin-left: 15px;
          text-align: left;
          padding: 30px;
          box-shadow: 0px 1px 4px #0052cc1a;

          @media (max-width:800px) {
            padding: 15px;
          }

          // @media (max-width: 1300px) {
          //   width: 400px;
          //   padding: 14px;
          // }

          // @media (max-width: 510px) {
          //   width: 248px;
          //   margin-left: 6px;
          //   padding: 10px;
          // }

          // p {
          //   @media (max-width: 510px) {
          //     font-size: 10px;
          //     margin-bottom: 6px;
          //   }
          // } need remove this
        }
      }

      .a-one {
        margin-left: -8% !important;

        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .b-one {
        margin-left: -1% !important;

        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .c-one {
        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .f-one {
        margin-left: -3% !important;

        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .d-one {
        margin-left: 6% !important;

        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .e-one {
        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }

      .g-one {
        margin-left: -8% !important;

        @media (max-width:800px) {
          margin-left: unset !important;
        }
      }


    }
  }
}

// pricing page
.pricing_main_page {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);
  height: 100%;
  padding-bottom: 222px;

  @media (max-width: 992px) {
    padding-bottom: 14px;
  }
}

.privatePricing {

  .pricing {
    .pricing_card_section {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1250px) {
        flex-direction: column;
      }

      .pricing-box {
        margin-top: -14.8%;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 1px 4px #0000001a;
        padding: 30px 20px;
        width: 290px;
        height: 480px;
        position: relative;
        margin-left: 40px;
        margin-right: 40px;

        @media (max-width: 1250px) {
          margin-top: 25px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        .paragraphs {
          margin-left: 20px;
          padding-bottom: 10px;

          .imgs-dev {
            display: flex;

            img {
              height: 24px;
              width: 24px;
              background: #f4f5f7 0% 0% no-repeat padding-box;
              border-radius: 6px;
              padding: 5px;
            }

            p {
              margin-left: 10px;
              margin-bottom: 10px;
            }
          }

          @media (max-width: 1250px) {
            width: 216px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .first_card {
          padding-bottom: 35px;
        }

        .senter {
          padding-bottom: 30px;
        }

        &:hover {
          outline: 2px solid #0052cc;
          cursor: pointer;
        }

        button {
          position: absolute;
          right: 15px;
          left: 15px;
          bottom: 25px;
        }
      }

      .active {
        outline: 2px solid #0052cc !important;
      }
    }
  }

  .pricing_table_section {
    padding-top: 44px;
  }

  .pricing_table_component {
    .table_responsive_class {
      @media (max-width: 767px) {

        th,
        td {
          font-size: 12px !important;
        }
      }
    }
  }
}

.guestPricing {
  .pricing {
    margin-top: -105px;

    @media (max-width:992px) {
      margin-top: -30px !important;
    }

    @media (max-width:450px) {
      margin-top: 0px !important;
    }

    .pricingBox {
      padding: 10px;
      height: 500px;
      position: relative;
      margin-bottom: 10px;

      @media (max-width:1200px) {
        height: 530px !important;
      }

      @media (max-width:450px) {
        height: 500px !important;
      }

      &:hover {
        outline: 2px solid #0052cc;
        cursor: pointer;
      }

      .card-body {

        ul {
          list-style: none;
          padding-bottom: 20px;

          @media (max-width:1258px) {
            padding-bottom: 30px !important;
            padding-left: unset !important;
          }

          @media (max-width:766) {
            padding-bottom: 20px !important;
            padding-left: 2rem !important;
          }

          li {
            display: flex;
            gap: 20px;
            padding: 10px 0px 10px 0px;

            .imageBox {
              background-color: #F4F5F7;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              object-fit: contain;
              border-radius: 5px;
            }
          }
        }


      }

      .cardFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px;

        .pricing-button {
          width: 100%;
          padding: 10px 0px;
        }
      }
    }
  }

  // }

  .pricing_table_section {
    padding-top: 44px;
  }

  .pricing_table_component {
    .table_responsive_class {
      @media (max-width: 767px) {

        th,
        td {
          font-size: 12px !important;
        }
      }
    }
  }
}

// Contact Us Page Scss

.contectus_page_section {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);
  height: 100%;
  padding-bottom: 126px;

  .top-text {
    margin-top: 6%;
  }

  @media (max-width: 992px) {
    padding-bottom: 12px;
  }
}

.forms-dev {
  padding-top: 90px;

  .img-dev {
    width: 36px;
    height: 36px;
    background-color: #e5edfa;
    border-radius: 10px;
    padding-top: 6px;
    padding-left: 1px;
    cursor: pointer;

    @media (max-width: 1365px) {
      padding-left: 0px;
      margin-top: -5px;
    }

    @media (max-width: 1366px) {
      padding-left: 0px;
    }
  }

  .fromes {
    padding: 10px 15px;
    font-size: 13px;
    outline: none;
    border-radius: 10px;
    height: 200px;
  }

  .form_section_column {
    margin-top: -20px;

    @media (max-width: 992px) {
      margin-top: 10px;
    }

    label {
      font-weight: 500;
      font-size: 14px;
    }

    .form-control__input {
      border: 1px solid #ebebeb;
    }
  }

  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.table-st {
  margin-top: 18px;
}

.main_content {
  .contant_section {
    .contant_heading {
      max-width: 350px;
      font-weight: 700;
    }

    .paragraph {
      font-weight: 600;
      text-align: justify;
      line-height: 29px;

      @media (min-width: 1366px) {
        max-width: 545px;
      }
    }

    .paragraph::first-line {
      word-spacing: 1px;
    }
  }

  .image_section {
    // padding-top: 114px;

    @media (max-width: 768px) {
      height: 100% !important;
      width: 100%;
      // padding-top: 90px;
    }

    // padding-right: 0;

    // .image {
    //     width: 100%;
    //     height: 100%;
    // }
    .home__image__section {
      margin-left: 28px;

      img {
        height: 100% !important;
        width: 100% !important;
      }

      @media (max-width: 768px) {
        background-size: contain;
        margin-left: 0px;
      }
    }
  }
}

// landing page companies section scss

.company_section {
  .company_section_headding {
    margin-top: 5px;
  }

  .image_main_section {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    // .image_section {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 0% 0.7%;

    //   @media (max-width: 768px) {
    //     margin-bottom: 50px;
    //   }

    //   img {
    //     padding: 5px 20px;
    //     background: #eee;
    //     border-radius: 10px;
    //     width: 100%;
    //   }
    // }
  }

  @media (max-width: 992px) {
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}

// manage project section scss

.project_manage_section {
  // padding-bottom: 145px !important;
  // padding-top: 18px;

  @media (max-width: 992px) {
    padding-top: 0px !important;
    padding-bottom: 20px !important;

    .landing_page_row {
      flex-direction: column !important;

      .image_column {
        width: 100%;
        padding-top: 0px !important;
        margin-top: 0px !important;
      }

      .manage_project_column {
        width: 100%;
      }
    }
  }

  .project_manage_image_section {
    .graph_img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .project_manage_contact_section {
    .project_manage_paragraph {
      font-weight: 600;
      // max-width: 475px;

      @media (max-width: 992px) {
        max-width: 100%;
      }
    }

    .project_details_lists {
      margin-bottom: 35px;
      margin-left: 15px;

      .list_contant {
        border-left: 4px solid #0052cc;
        border-radius: 4px;
        padding-left: 15px;

        @media (max-width: 992px) {
          min-height: 96px;
        }

        .text-paragraph {
          color: #707070;
          font-size: 16px;
        }
      }

      .landing_page_row {
        .col-md-6 {
          padding-left: 0;
        }
      }

      @media (max-width: 992px) {
        .landing_page_row {
          display: flex !important;
          flex-direction: row !important;

          .col-md-6 {
            width: 50%;
          }
        }
      }
    }

    @media (max-width: 992px) {
      padding-top: 50px;
    }
  }
}

// ladding page features card section
.features {
  background: #eef4ff;
  padding-bottom: 65px;

  @media (max-width: 992px) {
    padding-bottom: 50px;
  }

  // @media (max-width: 992px) {
  //   .landing_page_row {
  //     flex-direction: column;

  //     .col-md-3 {
  //       width: 100%;
  //       margin-top: 25px;
  //     }
  //   }
  // }

  h2 {
    padding: 47px 0px 40px 0px !important;

    @media (max-width: 560px) {
      padding: 35px 0px 25px 0px !important;
    }
  }

  .card {
    box-shadow: 0px 1px 4px #0052cc1a;
    border-radius: 10px;
  }
}

.feature-cards {
  border-radius: 10px;
  box-shadow: 0px 1px 4px #0000001a !important;
  height: 100%;
  padding-bottom: 7px;

  .feature_card_image {
    width: 76px;
    height: 76px;
    background-color: #e5edfa;
    border-radius: 10px;
    padding-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .feature_card_image-sharing {
    background: #fff0ed !important;
  }

  .card-title {
    font-size: 18px;
  }

  .paragraph {
    color: #707070;
    font-size: 16px;
  }
}

.about {
  .about_img {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }


  .paragraph {
    font-weight: 600;
    text-align: justify;
    line-height: 29px;
    margin-bottom: 6px;
  }

  @media (max-width: 992px) {
    .landing_page_row {
      flex-direction: column;

      .col-md-6 {
        width: 100%;
      }
    }
  }
}

// contact

.contact {
  padding-top: 100px !important;
  padding-bottom: 110px !important;

  @media (max-width: 992px) {
    padding-top: 50px !important;
    padding-bottom: 63px !important;
  }

  .paragraph-div {
    display: flex;
    justify-content: center;
    align-items: center;

    .paragraph {
      max-width: 500px;
      font-weight: 600;
    }
  }

  .subscibe_form {
    width: 450px;
    margin: auto;

    @media (max-width: 666px) {
      width: 100% !important;
    }

    .subscibe_form_error_msg {
      margin-top: 8px;
      margin-bottom: 0px !important;
      text-align: left;
    }
  }

  .in {
    box-shadow: 0px 0px 10px lightgray;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 21px;

    input {
      border: none;
      outline: none;
    }

    .btn-loader_email {
      width: 154px;
      height: 47px;
    }
  }
}

// carousel

.landing__carousel {
  background: #edf4ff;
  position: relative;

  #carouselExample {
    display: flex;
    justify-content: center;
  }

  .carousel-inner {
    width: 80%;
    margin-left: 23px;

    @media (max-width: 992px) {
      margin-left: 8px;
    }
  }

  .carousel_box {
    padding-top: 40px;

    .slick-slider {
      .slick-prev {
        left: -27px;

        @media (max-width:1200px) {
          left: -23px !important;
        }

        @media (max-width: 992px) {
          left: -8px !important;
        }

        @media (max-width: 512px) {
          left: -20px !important;
        }
      }
    }
  }

  .cading {
    border-radius: 10px;
    // margin-right: 12px;
    max-width: 90%;
    min-height: 330px;


    @media (max-width: 1880px) {
      // max-width: 90%;
      /* Changed width to max-width */
      border-radius: 10px;

      // margin-left: auto;
    }



    .card-body {
      padding: 40px;
    }

    @media (max-width: 999px) {
      .card-body {
        padding: 20px !important;
      }

      .slick-slider {
        width: 100% !important;
      }
    }

    .card-text {
      margin: auto;
      font-size: 16px;
      color: #707070;
      // text-align: justify;
      margin-top: 16px;
      margin-bottom: 10px;
      line-height: 25px;
      font-weight: 500;
      word-spacing: -1px;

      @media (min-width: 1540px) {
        line-height: 23px;
        font-size: 15.4px;
      }

      @media (min-width: 1740px) {
        line-height: 23px;
        font-size: 16px;
      }
    }

    .image {
      width: 40px !important;
      height: 40px !important;
      border-radius: 10px;
      object-fit: cover;
    }

    .img-tested {
      width: 30px;
      height: 25px;
      position: absolute;
      top: 60px;
      right: 25px;

      @media (max-width: 768px) {
        top: 50px;
      }
    }

    .name {
      margin-left: 15px;

      h5 {
        height: 0;
        font-size: 20px;
      }

      .icons {
        color: goldenrod;
        font-size: 12px;
        margin: 3px;
        padding-top: 26px;
        margin-left: 0%;
      }
    }

    &:hover {
      box-shadow: 0px 0px 12px lightgray;
    }

    @media (max-width: 992px) {
      max-width: 99%;
      /* Changed width to max-width */
      height: 100%;
      margin-right: auto;
      margin-top: 25px;
      margin-right: 25px !important;
    }
  }

  .cading-b {
    margin-left: 32px !important;

    @media (max-width: 992px) {
      margin-left: auto !important;
    }
  }

  .prev_btn {
    background: #0052cc;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    border-radius: 6px;
    margin-left: 2px;
    background-color: #0052cc !important;
    opacity: 1;

    // @media (min-width: 1280px) {
    //   left: -2%;
    // }

    // @media (min-width: 1340px) {
    //   left: -1%;
    // }

    // @media (min-width: 1360px) {
    //   left: -0%;
    // }

    // @media (min-width: 1380px) {
    //   left: 1%;
    // }

    // @media (min-width: 1400px) {
    //   left: 2%;
    // }

    // @media (min-width: 1420px) {
    //   left: 2.5%;
    // }

    // @media (min-width: 1440px) {
    //   left: 3%;
    // }

    // @media (min-width: 1460px) {
    //   left: 3.5%;
    // }

    // @media (min-width: 1480px) {
    //   left: 4%;
    // }

    // @media (min-width: 1500px) {
    //   left: 5%;
    // }

    // @media (min-width: 1540px) {
    //   left: 6%;
    // }

    // @media (min-width: 1560px) {
    //   left: 8%;
    // }

    // @media (max-width: 1440px) {
    //     left: 6%;
    // }

    .carousel-control-prev-icon {
      height: 10px;
      opacity: 0.9;
    }
  }

  .next_btn {
    background: #0052cc;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    border-radius: 6px;
    margin-right: -15px;
    background-color: #0052cc !important;
    opacity: 1;

    // @media (min-width: 1280px) {
    //   right: -2%;
    // }

    // @media (min-width: 1340px) {
    //   right: -1%;
    // }

    // @media (min-width: 1360px) {
    //   right: 0%;
    // }

    // @media (min-width: 1380px) {
    //   right: 1%;
    // }

    // @media (min-width: 1400px) {
    //   right: 2%;
    // }

    // @media (min-width: 1420px) {
    //   right: 2.5%;
    // }

    // @media (min-width: 1440px) {
    //   right: 3%;
    // }

    // @media (min-width: 1460px) {
    //   right: 3.5%;
    // }

    // @media (min-width: 1480px) {
    //   right: 4%;
    // }

    // @media (min-width: 1500px) {
    //   right: 5%;
    // }

    // @media (min-width: 1540px) {
    //   right: 6%;
    // }

    // @media (min-width: 1560px) {
    //   right: 8%;
    // }

    // @media (max-width: 767px) {
    //   margin-right: 0;
    // }

    .carousel-control-next-icon {
      height: 10px;
      opacity: 0.9;
    }
  }

  .carousel_responsive {
    .carousel-item {
      .landing_page_row {
        @media (max-width: 992px) {
          flex-direction: column;

          .col-md-6 {
            width: 100%;
          }
        }
      }
    }
  }
}

// footer

.footer_wrapper {
  padding-top: 100px;
  background: linear-gradient(119deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(183, 211, 254, 0.26094187675070024) 50%,
      rgba(255, 255, 255, 1) 95%);
  padding-bottom: 22px;

  .footer_content_wrapper {
    @media (max-width: 992px) {
      margin-top: 20px;
      margin-bottom: -6px !important;
    }
  }

  .homes {
    cursor: pointer;
  }

  .d_flex {
    display: flex;

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column !important;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column !important;
    }
  }

  .logo_para {
    color: #000000;
    margin-top: 20px;
    line-height: 1.8;
    font-weight: 600;
  }

  .zack {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 3px 14px #0052cc24;

    img {
      font-size: 18px;
      filter: invert(0%);
    }

    &:hover {
      background: #0052cc;

      img {
        filter: invert(100%);
      }
    }
  }

  .services_text {
    font-size: 16px;
  }



  .social-icon {
    background: #fff;
    padding: 4%;
    box-shadow: 0px 3px 14px #0052cc24;
    border-radius: 10px;

    &:hover {
      background-color: #0052cc;
      color: #fff;
      mix-blend-mode: luminosity;
    }

    i {
      font-size: 25px;
      color: #000;
      margin-right: 18px;
      cursor: pointer;
    }

    .fa-envelope-o {
      font-size: 20px;
    }
  }

  .footer_col_four {
    .first_li {
      line-height: 1.3;
      margin-bottom: 10px;
      max-width: 182px;
    }

    padding-left: 12.6%;

    h4 {
      color: #000;
      margin-bottom: 18px;
      margin-top: -6px;
      font-size: 20px;
    }

    ul {
      li {
        line-height: 2.5;

        a {
          text-decoration: none;
          color: #000;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    @media (max-width: 767px) {
      padding-left: 20px !important;
      padding-top: 30px;
    }
  }
}

.service_box {
  .level_img {
    width: 50px !important;
    height: auto;
  }
}

.landing_page_row {
  margin-left: 0;
  margin-right: 0;
  //   height: 600px;
}

.landing_image_main {
  height: 600px;
}

// footer-border

.footer-border {
  border-top: 1px solid #eeeeee;
}

.response_error {
  color: #fa2626 !important;
}

.carousel {
  padding-bottom: 100px !important;

  @media (max-width:992px) {
    padding-bottom: 70px !important;
  }
}

.input_error {
  border: 1px solid #fa2626 !important;
}

.carousel-item {
  transition: transform 0.5s ease;
}

.carousel-inner {
  overflow: hidden;
}

.slick-prev:before {
  content: unset;
  font-size: unset !important;
}

.MuiSvgIcon-fontSizeLarge {
  height: 25px !important;
  width: 25px !important;
  background: #0064cb;
  border-radius: 4px;
  color: #fff !important;
}

.slick-slider {
  width: 80% !important;
}

.slick-next::before {
  display: none;
}

.slick-list {
  padding-left: 23px;

  @media (max-width: 512px) {
    padding-left: 16px;
  }
}

.paragraph__content {
  margin-top: 7px !important;
  margin-bottom: 11px;

  @media (max-width: 1365px) {
    margin-top: 2px !important;
    margin-bottom: 15px;
  }
}

.screen-sharing-bg {
  background: #fff0ed !important;
}

.user_billing_report {
  padding: 12px;

  .add_card_action {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .add_card_btn {
      padding: 12px 40px;
    }
  }
}

.user_payment_card {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 10px;
  padding: 12px 20px;
  width: 75%;
  margin-top: 18px;

  .payment_card_section_1 {
    display: flex;

    .payment_card_name_section {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .card_name {
        color: #0052cc;
        font-weight: 600;
      }

      .card_code {}
    }
  }

  .created_at {
    font-size: 12px;
    width: 113px;
    margin-top: 15px;
  }

  .payment_card_section_2 {
    display: flex;
    justify-content: end;
    align-items: center;

    .card_status {
      color: #35ac50;
      font-weight: 600;
      margin-right: 15px;
    }

    .card_del_btn {
      color: #0052cc;
      padding: 8px 40px;
      font-size: 15px;
      border: 1px solid #0052cc;
    }
  }
}

.user_select_box {
  display: flex;

  .user_info_checkbox {
    margin: auto 0;
  }

  .user_info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .user_name {
      font-weight: 500;
    }

    .user_email {
      font-size: 12px;
      color: #7a7a7a;
    }
  }
}

.user_checkout_submit {
  padding: 12px 50px !important;
}

.color_light_gray {
  color: #7a7a7a;
}

// extension page
.extension_header {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);
  min-height: 590px;
}

.container-latest-update.container__land {
  @media (min-width:1400px) {
    width: 1100px;
  }
}

.latest-update {
  min-height: unset !important;
  padding-bottom: 77px;
}

@media (min-width: 666px) and (max-width: 1200px) {
  .extension_header.latest-update {
    min-height: unset !important;
  }
}

.h-304 {
  height: 304px !important;
}

.latest-update_cards {
  padding: 30px 0 30px 0;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .col-md-6 {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width:768px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .card {
    transition: transform 0.3s ease, padding-bottom 0.3s ease;
    backface-visibility: hidden;
    transform: translateZ(0);

    &:hover {
      transform: translate3d(0, 2px, 0) scale(0.99);
    }

    padding: 30px 30px 0px 30px;

    .image img {
      height: 40px;
    }

    .heading {
      padding-top: 10px;
      padding-bottom: 20px;

      h1 {
        font-size: 1.5em;
      }

      p {
        color: #9EA2AB;
        font-size: 1em;
        margin: 0;
        padding-top: 10px;

        .see-more {
          cursor: pointer;
        }
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .bottom-img {
      height: 280px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      box-shadow: 0 1px 4px rgba(0, 82, 204, 0.102);

      @media (max-width:416px) {
        height: unset !important;
      }

      img {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.extention_feature_heading {
  max-width: 60%;

  @media (max-width: 992px) {
    max-width: 81%;
  }

  @media (max-width: 767px) {
    text-align: center;
    margin: 30px auto 0;
  }
}

.extention_feature_content {
  width: 83%;

  @media (max-width: 992px) {
    width: 90%;
  }

  @media (max-width: 767px) {
    text-align: center;
    margin: auto;
  }
}

.extention_dashboard_img {
  width: 70%;
  margin: -10rem auto 0;

  img {
    border-radius: 10px;
    box-shadow: 0px 1px 4px #0000001a;
  }

  @media (max-width: 767px) {
    margin: -15% auto 0;
  }
}

.extension_feature_text {
  height: 100%;
  display: flex;
  align-items: center;
}

.extension_select_card_title {
  text-align: center;
  padding: 15px;

  @media (max-width: 767px) {
    padding: 8px;
  }

  .title {
    max-width: 60%;
    margin: auto;

    @media (max-width: 1070px) {
      max-width: unset;
    }

    @media (max-width: 767px) {
      font-size: 13px;
      max-width: unset;
    }
  }
}

.card__container_extension {
  display: flex;

  .extension_card_heading {
    width: 35%;
    display: flex;
    align-items: center;

    ._extension_feature {
      max-width: 75%;
      margin-left: auto;

      @media (max-width: 767px) {
        max-width: unset;
        font-size: 24px;
        margin-left: 30px;
      }
    }
  }

  .extension__cards {
    display: flex;
    overflow: auto;
    gap: 20px;
    padding: 10px;
    width: 70%;

    .extension_select_card {
      border-radius: 10px;
      box-shadow: 0px 1px 4px #0000001a;
      cursor: pointer;
    }

    .extension_select_card.selected {
      transform: translateY(-5px);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease, box-shadow 0.2s ease;
    }

    .extension_select_card.col-md-3 {
      width: 28%;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.extension_feature_card {
  width: 80%;
  margin-left: auto;

  @media (max-width: 767px) {
    margin: auto;
  }
}

.extension_select_card_img {
  @media (max-width: 1000px) {
    img {
      border-radius: 10px;
    }
  }
}

.color_unset {
  color: unset !important;
}

.bg_unset {
  background: unset !important;
}

.extention_icon_section {
  width: 68%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 3% auto;
  border: 2px solid #eaeaea;
  padding: 15px 40px 25px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.102);

  @media (max-width: 915px) {
    justify-content: space-around;
    gap: 10%;
  }

  .extention_icon_div {
    cursor: pointer;
    margin-top: 20px;

    .title {
      margin-top: 15px;
      color: #707070;
    }
  }

  .selected {
    img {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }

    .title {
      color: unset !important;
    }
  }
}



.extention_get_btn {
  padding: 11px 40px;
}

.extendedfooter {
  background: linear-gradient(119deg,
      rgb(255, 255, 255) 5%,
      rgba(183, 211, 254, 0.2609418768) 50%,
      rgb(255, 255, 255) 95%);
}

.padding_right {
  padding-right: 100px !important;
}

.file-view-content {
  padding-top: 30px;
}

.exetension_work_heading {
  margin-top: 30px;
  padding-top: 30px;
}


.getStartedFree {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;

  .bgColor {
    background-color: #D2E0F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 30px;
    width: 50%;
    border-radius: 10px;
    flex-wrap: wrap;

    .get_start_text {
      font-size: 18px !important;
      font-weight: 500;
    }
  }


}