.blue-div {
  .blue {
    background: #0052cc;
    border-radius: 8px;
    width: 100px;
    height: 100px;
  }
}

.editor-class.rdw-editor-main {
  padding-left: 12px;
}

.color-div {
  padding-left: 5px;

  .white {
    background: #fff;
    border-radius: 8px;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
  }

  .liglt-gray {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #f4f5f7;
  }

  .gray {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #707070;
  }

  .black {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #000000;
  }

  .pink {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #ff7452;
  }

  .light-blue {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #2684ff;
  }

  .green {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #28a745;
  }

  .yellow {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    // border: 1px solid;
    background: #ffab00;
  }
}

.buttons .main p {
  // font-family: "Poppins", Medium;
}

// dashboard scss
.head_section {
  width: 100%;

  .head_section_heading {
    float: left;
  }

  .right_section {
    float: right;

    .input_section {
      display: flex;

      p {
        margin-top: 4px;
      }

      .input_fiels {
        position: relative;

        input {
          outline: none;
          border-radius: 10px;
          border: 1px solid #9b9d9e;
          padding-left: 10px;
          margin-left: 20px;
          width: 110px;

          &::placeholder {
            font-size: 12px;
            color: #707070;
          }
        }

        .left_input_field {
          width: 140px;
        }

        .dropdown_icon {
          position: absolute;
          right: 15px;
          color: #707070;
          font-size: 12px;
          top: 7px;
        }
      }
    }
  }
}

.dashboard_card_row {
  .item {
    margin-bottom: 17px !important;
  }

  .task_items_date {
    width: 23%;
  }
}

.graph_section {
  .text {
    display: flex;
    justify-content: center;
  }
}

.curcal_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 41px;

  .background_curcal_section {
    height: 155px;
    width: 155px;
    border-radius: 100%;
    padding: 7px;

    .curcal {
      height: 100%;
      width: 100%;
      z-index: 999;
      background: #fff;
      border-radius: 100%;
      text-align: center;
      padding-top: 35px;
    }
  }
}

.count_section {
  margin-top: 50px;
  padding-top: 20px;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      list-style: none;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 15px;
      text-align: center;

      p {
        margin-bottom: 2px;
        color: #707070;
      }
    }

    .empty_line {
      width: 2px;
      height: 30px;
      background: #9b9d9e;
      padding: 0 0;
      margin-top: 10px;
    }

    .empty_line_shimmerBG {
      background: #ddd !important;
    }

    .inprogress_count {
      p {
        color: #ffab00;
      }
    }

    .completed_count {
      p {
        color: #28a745;
      }
    }
  }
}

.dashboard_prob_box {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  min-height: 450px !important;

  .box-body.right_box_scroll-able {
    max-height: 375px !important;
    min-height: unset !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }

  .right_box_scroll-able::-webkit-scrollbar {
    width: 4px;
  }

  .right_box_scroll-able::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
    // border-top-right-radius: 10px;
  }

  .right_box_scroll-able::-webkit-scrollbar-track {
    background: #d8d9db;
  }
}

.right_box_scroll-able {
  max-height: 360px !important;
  min-height: 0 !important;
  min-height: auto !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.dashboard_bottom_box {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  min-height: 428px !important;
  margin-top: 25px;

  .box-head {
    padding-left: 10px;
  }

  .box-body.right_box_scroll-able {
    max-height: 337px !important;
    min-height: unset !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }

  .right_box_scroll-able::-webkit-scrollbar {
    width: 4px;
  }

  .right_box_scroll-able::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
    // border-top-right-radius: 10px;
  }

  .right_box_scroll-able::-webkit-scrollbar-track {
    background: #d8d9db;
  }
}

.dashboard_bottom_box.h-unset {
  min-height: unset !important;
  max-height: unset !important;
}

.right_box {
  padding-bottom: 10px;

  .box-body {
    p {
      margin-bottom: 5px;
    }
  }
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 12px) scale(1) !important;
}

.css-1jy596b-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 12px) scale(1) !important;
  display: none;
}

.css-yjsfm1 {
  max-width: 0.01px !important;
}

.css-14lo706 {
  max-width: 0.01px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #1976d2;
  display: none;
}
