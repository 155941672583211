.calender_loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.calendar-scrumslate {
  .rbc-month-view > div:last-child {
    border-radius: 0px 0px 10px 10px;
  }

  .rbc-month-view {
    border-radius: 10px !important;

    .rbc-row .rbc-date-cell {
      .rbc-button-link {
        font-size: 20px;
        color: #707070;
      }
    }

    .rbc-month-header {
      .rbc-header span {
        font-size: 16px;
      }
    }

    .rbc-month-header > div:nth-child(1) {
      border-radius: 10px 0px 0px 0px;
    }

    .rbc-month-header > div:nth-child(7) {
      border-radius: 0px 10px 0px 0px;
    }
  }

  .rbc-row-segment {
    border-radius: 6px;

    .rbc-event {
      background: unset;
      border-radius: 6px;
      cursor: unset !important;
    }

    .rbc-event-content {
      .icon {
        margin: 7px 0px 7px 0px !important;
      }

      p {
        color: #292929;
        margin: 0px 0px 0px 5px !important;
        font-size: 16px;
        // font-family: Poppins, Medium;
      }
    }
  }

  .rbc-agenda-view {
    table {
      tbody > tr > td {
        vertical-align: unset !important;
        padding-top: 10px !important;
      }

      th {
        font-size: 16px;
      }
    }
  }
}

.rbc-agenda-time-cell,
.rbc-agenda-time-header {
  display: none;
}

.rbc-agenda-view > table:first-child {
  border-radius: 10px !important;
  border: unset !important;

  thead > tr > th:first-child {
    border-radius: 10px 0 0 0;
  }

  thead > tr > th:last-child {
    border-radius: 0px 10px 0px 0px;
  }
}

.rbc-agenda-view th:nth-child(2) {
  display: none;
}

.rbc-agenda-view .rbc-agenda-date-cell {
  background: white;
  padding-top: 60px !important;
  font-weight: bold;
}

// .rbc-agenda-view .rbc-agenda-event-cell {
//   color: #4281CA;
//   cursor: pointer;
// }

.rbc-toolbar {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 10px;
  font-size: 14px !important;
  // font-family: "Poppins", Medium !important;

  .control_icon {
    cursor: pointer;
  }

  .toolbar_label {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .view_button {
    border-radius: 10px !important;
    padding: 9px 14px;

    &:active {
      outline: none !important;
    }

    &:hover {
      color: #2684ff !important;
      background-color: #e6f2ff !important;
    }
  }

  .view_active {
    color: #2684ff !important;
    background-color: #e6f2ff !important;
    border: 1px solid #e6f2ff !important;
  }
}

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px !important;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
  border-left: 0px !important;
  border-right: 0px !important;
  background: #f4f5f7;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: unset !important;
  border-radius: unset !important;
  margin: unset !important;
}

.rbc-month-row {
  .rbc-row {
    height: 48px;
  }
}
