@mixin iconStyle {
  font-size: 40px !important;
  font-weight: 500 !important;
}

@mixin paginationSelected {
  .Mui-selected {
    color: #fff !important;
    background-color: #0052cc !important;
  }
}

@mixin navBarDropDownMenu($translateX, $translateY) {
  .navCustomMenu {
    border: none !important;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px !important;
    border-radius: 10px !important;
    height: auto !important;
    top: 0px !important;
    inset: 0px 0px auto auto !important;
    transform: translate(#{$translateX}, #{$translateY}) !important;
    min-width: 10px;
  }

  .dropIcon {
    font-size: 1.25rem !important;
  }
}

.outlet_main_content {
  min-height: 100vh;
}

#closeBtn {
  ._1fpgA {
    height: 30px !important;
  }
}

.prevControl {
  @include iconStyle();
}

.nextControl {
  @include iconStyle();
}

.fs_20 {
  font-size: 20px !important;
}

.placeholder_color {
  color: #7a7a7a !important;
}

.span-gray-color {
  color: #707070 !important;

}

.br_6 {
  border-radius: 6px !important;
}

.br_4 {
  border-radius: 4px !important;
}

.img_20 {
  height: 20px;
  width: 20px;
}

.navbar_container {
  min-height: 0px !important;
}

.show.dropdown {
  .button_style a {
    color: #0052cc !important;
  }
}

.user_label {
  margin-right: 5px;
  font-size: 14px !important;
}

.deselect_label {
  color: rgb(235, 235, 235);
  font-size: 12px !important;
  padding: 1px;
  cursor: pointer;
  border-radius: 50%;
}

.br_15 {
  border-radius: 15px !important;
}

.br_10 {
  border-radius: 10px !important;
}

.w-fit_content {
  width: fit-content !important;
}

.new_tab_icon {
  color: lightgray;
  font-size: 1rem !important;
  margin: 0px 0px 0px 5px !important;
}

.latest_release-heading {
  width: 40%;
  display: flex;

  @media (max-width: 1020px) {
    width: 30%;
  }

  .new_tab_icon {
    margin-top: 2px !important;
  }

  .new_tab_icon:hover {
    color: #0052cc !important;
    cursor: pointer;
  }
}

.sprint_heading {
  color: unset !important;
  font-size: unset !important;
  margin-left: unset !important;
}

.listcta_heading {
  max-width: 220px;
  display: flex;
  align-items: center;

  .new_tab_icon:hover {
    color: #0052cc !important;
    cursor: pointer;
  }
}

.list_task_count {
  font-size: 14px;
  color: #707070;
  margin-left: 18px;
}

.list_task_count.testcase-count {
  margin-left: 10px;
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-12 {
  margin-top: 12px !important;

}

.project_file_size {
  margin-top: 8px !important;
}

.file_padding_none {
  padding: unset !important;

  img {
    height: 75%;
    margin-left: 12.5%;
  }

  .delete_file {
    position: absolute !important;
  }

  .file_top_section {
    align-items: center;
  }
}

.navCustomDropDown {
  @include navBarDropDownMenu(0px, 52px);

  .navCreateTaskBtn {
    width: 110px;
    position: relative;
    padding-left: 12px;
  }

  .dropIcon {
    position: absolute;
    right: 10px;
  }

  a {
    color: #707070;
    font-size: 14px;
    font-weight: 500;

    &:active {
      color: #707070 !important;
      background-color: unset !important;
    }
  }
}

.navSettingDropDown {
  @include navBarDropDownMenu(-20px, 28px);

  .dropdown-item:active {
    background: unset !important;
  }
}

.file_main_section_loader {
  height: fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);

  .file_img_loader {
    height: 130px;
    width: 140px;
    border-radius: 6px;
  }

  .file_item_loader {
    height: 15px;
    margin-top: 5%;
    border-radius: 6px;
  }

  .file_content_loader {
    width: 20%;
  }

  .file_content_user {
    width: 100%;
  }
}

.add_cursor {
  cursor: pointer !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.h-14 {
  height: 14px !important;
}

.h-5 {
  height: 5px !important;
}

.h-150 {
  height: 150px !important;
}

.btn-load-normal {
  height: 48px;
  width: 110px;
  border-radius: 6px;
}

.f-12 {
  font-size: 12px !important;
}

.f-11 {
  font-size: 11px !important;
}

.mb_12 {
  margin-bottom: 12px !important;
}

.mb_5 {
  margin-bottom: 5px !important;
}

.mb_15 {
  margin-bottom: 15px !important;

}

.mb_30 {
  margin-bottom: 30px !important;
}

.ccard-main {
  width: 40rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.main_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.inner_loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  z-index: 1000;
}

.shimmer_background {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  background: #ffffff !important;
}

.w_168px {
  width: 168px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.ml-6px {
  margin-left: 6px;
}

.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
  color: transparent;
  color: transparent !important;
}

.background_curcal_section_shimmer {
  height: 155px;
  width: 155px;
  background: conic-gradient(#f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  border-radius: 100%;
  padding: 7px;

  .curcal {
    height: 100%;
    width: 100%;
    z-index: 999;
    background: #fff;
    border-radius: 100%;
    text-align: center;
    padding-top: 35px;
  }
}

.shimmer_text {
  color: #ddd !important;
}

.shimmer_task_icon {
  background-color: #ddd;
}

.main-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  width: 100%;
  padding: 11px 10px;
  margin: 18px auto 0px;
}

.section1 {
  display: flex;
  gap: 10px;
}

.section2 {
  display: flex;
  gap: 20px;
  align-items: center;
}

.div1,
.div2,
.div3,
.div4,
.div5,
.div6,
.div7,
.div8,
.div9 {
  height: 26px;
  border-radius: 6px;
}

.div1 {
  width: 25px;
}

.div2 {
  width: 180px;
}

.div3,
.div4 {
  width: 80px !important;
}

.div5 {
  width: 120px !important;
}

.buttonShimmer {
  height: 36px;
}

.float_right {
  position: relative;
  left: 97%;
}

.div6,
.div7,
.div8,
.div9 {
  width: 30px;
}

.ccard {
  width: 20rem;
  height: 20rem;
  background: rgb(109, 148, 255);
  border-radius: 5px;
  transition: box-shadow 0.5s;
  will-change: transform;
}

@media (min-width: 1200px) {
  .card-container {
    padding: 0 5em;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .responsive-img {
    width: 35% !important;
  }

  .responsive-header {
    margin-left: -7em !important;
    padding-right: 10px !important;
  }

  .responsive-header-text {
    font-size: var(--font-size-12) !important;
  }
}

.index-issue {
  // position: absolute;
  z-index: 9 !important;
  // position:sticky
}

.navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  background: white;
  padding: 8px 36px;
}

.navbar-new-navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  background: white;
  padding: 0px 36px;
}

.navbar-fixed-new {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  right: 0;
  background: white;
  padding: 8px 0px;
  border-bottom: 1px solid var(--gray3);
}

.padding-new-navbar {
  padding: 4px 0px 4px;
}

.profile-icon {
  img {
    /* height: 70px;
       width: 70px;*/
    color: #2e47cc;
    background: rgba(46, 71, 204, 0.2);
    padding: 16px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.upload-resume-section {
  svg {
    height: 18px;
    width: 18px;
    color: #696871;
    background: #ffffff;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
    margin-left: -14px;
    margin-bottom: 55px;
  }
}

.upload-resume-container {
  border: 1px dashed #696871;
  /*  width: 19em;*/
  padding: 20px;
  height: 9em;
  margin: 25px;
  border-radius: 8px;
}

.upload-resume-preview {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 590px;
  /* top: 250px;*/
  border: 1px solid #eae9f2;
  box-sizing: border-box;
  border-radius: 50%;

  img {
    margin-top: 10px;
  }

  svg {
    position: absolute;
    left: 65.46%;
    right: 26.47%;
    top: -5.03%;
    bottom: 23.97%;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.back-button {
  svg {
    font-size: 13px;
  }
}

.tab-container {
  .MuiGrid-root {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: grid;
  }
}

.create-skill-badge-block {
  .badge {
    border: 1px solid #002962;
    border-radius: 10px;
    color: #262626;
    background-color: #f7f7fb;
    padding: 0.1rem 0.8rem;
    align-items: center;
    justify-content: center;
    margin: 0.1em 0.5em 0 0;
    line-height: 1.9;
    height: auto;
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 0.8rem;
    white-space: inherit;
    font-weight: normal;
  }

  .close {
    opacity: 0.6;
    font-size: 1.5em;
    color: #58595b;
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-left: 7px;
  }
}

.settings-layout {
  margin: 0 30px;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .settings-small-screen-resolution {}

  .settings-big-screen-resolution {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .settings-big-screen-resolution {}

  .settings-small-screen-resolution {
    display: none;
  }
}

@media (min-width: 1200px) {
  .settings-big-screen-resolution {}

  .settings-small-screen-resolution {
    display: none;
  }
}

/*css for publish screen*/
.main-publish-container {
  overflow-y: auto;
  height: 100vh;
}

.header-fixed {
  position: sticky;
  top: 0;
  z-index: 9;
}

.main-template-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 85vh;
}

.client-portal-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
}

.open-button {
  bottom: 25px;
  left: 86em;

  img {
    right: 30px;
    width: 50px;
  }
}

.background-image {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 480px;
  left: 0;
  top: 0;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.testimonial-right-container {
  img {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }
}

.testimonial-profile {
  img {
    width: 50%;
  }
}

.testimonial-img {
  img {
    width: 75%;
  }
}

.who-we-are-container {
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

.join-us-container {
  img {
    width: 100%;
    object-fit: cover;
    height: 420px;
  }
}

.meet-the-team {
  img {
    width: 100%;
  }
}

.life-at-company {
  img {
    width: 100%;
    height: 85%;
    object-fit: cover;
  }
}

.ready-to-join-us {
  background-size: cover;
  height: 480px;

  img {
    width: 100%;
  }
}

.who-we-are-container {
  background-size: cover;
  height: 480px;

  img {
    width: 100%;
  }
}

.responsive-img {
  img {
    width: 50%;
  }
}

.background-image-who-we-are {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.background-image-get-ready-to-join {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.testimonial-profile-card {
  img {
    width: 30%;
  }
}

.header-img {
  img {
    width: 100%;
  }
}

.MuiAppBar-colorPrimary {
  background-color: #ffffff !important;
}

.nugget-section {
  padding: 12px 10px;

  .nugget-container {
    display: flow-root;
  }
}

.user-pic-block {
  .profile-pic {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile {
      padding: 3px;
      background-color: #fff;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .profile-file-upload {
      position: absolute;
      display: none;
    }
  }
}

.user-pic-block {
  .profile-pic {
    &:hover {
      .profile-file-upload {
        display: block;
        position: absolute;
      }
    }
  }
}

.container-scroll {
  min-height: 100vh;
}

.pdf-icon-section {
  height: 80%;
  width: 15%;
  object-fit: cover;
}

.shadow-1 {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.shadow-new {
  border: 1px solid #e4e7ec !important;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
}

.border-bottom-gray4 {
  border-bottom: 1px solid var(--gray4) !important;
}

.container {
  position: relative;
  width: 100%;
}

.videoSection {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;

  video {
    border-radius: 14px !important;
    left: 0px !important;
    position: relative;
    right: 0px !important;
    width: 100% !important;
    height: auto !important;
  }
}

.videoSectionImage {
  height: 100%;
  width: 76%;
  top: 0;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 52px;

  img {
    position: absolute;
    left: 12px !important;
    border-radius: 14px;
    width: auto;
    height: 280px;
    right: 0px !important;
    display: none !important;
  }
}

.videoMiddleSection {
  transition: 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container .videoMiddleSection {
  opacity: 1;
}

.overlay-image {

  /*  color: #fff;
    height: 11em;
    width: 11em;
    border-radius: 50%;
    border: 2px dashed;*/
  img {
    position: absolute;
    top: -29px;
    left: -12px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent gray */
  z-index: 9999;
  /* Ensure the overlay appears on top of other elements */
}

.pause-image {
  margin-top: -9px;
  margin-left: -10px;
}

@media screen and (min-width: 300px) and (max-width: 516px) {
  .videoSectionImage {
    height: 60%;
    width: 89%;
    top: 58px;
    position: absolute;
    //   background-image: url('/assets/images/videoDefaultSection.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 16px;
  }

  .overlay-image {
    color: #fff;
    height: 6.5em;
    width: 6.5em;
    border-radius: 50%;
    border: 2px dashed;
  }
}

.candidate-draft {
  left: 78px;
  top: 2px;
}

.video-image {
  object-fit: cover;
}

.choose-video {
  background: #f9fafb;
  border: 1px dashed #98a2b3;
  border-radius: 8px;
}

.record-video {
  border-radius: 14px;
}

.video-upload-shadow {
  position: absolute;
  background-color: var(--gray10);
  opacity: 0.9;
  // width: 373px;
  // left: 66px !important;
  // top:237px !important;
  border-radius: 0px 0px 14px 14px;
  right: 0px !important;
  width: 100% !important;
  bottom: 5px !important;
}

.lazy-loader {
  position: relative;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1019607843);
  background-color: #fff;
  border-radius: 6px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  padding: 20px;

  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  .shimmer_card_section {
    width: 30%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }

  .p-32 {
    padding: 32px;
  }

  .p-10 {
    padding: 10px;
  }

  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.no-box-shadow {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.lazy-loader2 {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  padding: 20px;

  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  .file-img {
    .content-line {
      height: 89px;
    }
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }

  .small-media {
    height: 50px;
  }

  .p-32 {
    padding: 32px;
  }

  .px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .mx-32 {
    margin-left: 40px;
  }

  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .title-line-small {
    height: 24px;
    width: 50%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .title-line-small2 {
    height: 20px;
    width: 50%;
    margin-bottom: 12px;
    border-radius: 6px;
  }

  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .content-line_file {
    margin-bottom: 10px;
  }

  .content-line2 {
    height: 10px;
    width: 100%;
    margin-bottom: 0px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.file_loader {
  width: 13% !important;
}

.lazy-loader2_top {
  background: unset;
  box-shadow: unset;
  padding: unset;

  .px-32 {
    padding: unset !important;
  }

  .title-line-w25 {
    width: 25%;
  }

  .title-line_head {
    width: 25%;
    height: 30px;
  }

  .title_line_45 {
    width: 45%;
    height: 15px;
    border-radius: 6px;
  }

  .title-line-w20 {
    width: 20%;
  }

  .title-line-w10 {
    width: 10%;
  }

  .title-line-w18 {
    width: 18%;
  }

  .title-loading {
    height: 43px;
    border-radius: 10px;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .right-items {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .title-line-small {
      height: 43px;
      border-radius: 10px;
    }
  }
}

.loader-in-header {
  position: relative;
  width: 100%;
  padding: 7px;

  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }

  .p-32 {
    padding: 32px;
  }

  .title-line {
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  .content-line {
    height: 6px;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.m-t-24 {
  margin-top: 24px;
}

.card-view-interview {
  border: 1px solid #eae9f2;
  box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12),
    0px 10px 32px -4px rgba(90, 136, 193, 0.12);
  border-radius: 10px;
  min-height: 240px;
}

.card-view-interview-inside {
  background: #ffffff;
  box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12),
    0px 10px 32px -4px rgba(90, 136, 193, 0.12);
  padding: 22px 15px 25px 25px !important;
  border-radius: 0px 0px 10px 10px;
  min-height: 240px;
  margin-top: 10px;
  overflow: auto;
}

.datebox {
  margin-right: 20px !important;
  min-width: 65px;
  background: rgba(68, 91, 199, 0.1);
  border-radius: 6px;
  padding: 10px;
}

.interview-select {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      background-color: transparent !important;
      padding: 12px 12px 12px 4px !important;
      margin-right: 10px !important;
    }
  }

  .card-view-interview-inside {
    background: #ffffff;
    // border: 1px solid #EAE9F2;
    box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12),
      0px 10px 32px -4px rgba(90, 136, 193, 0.12);
    // border-radius: 10px;
    padding: 22px 15px 25px 25px !important;
    border-radius: 0px 0px 10px 10px;
    min-height: 230px;
    margin-top: 10px;
    overflow: auto;
  }

  .MuiSvgIcon-root-MuiSelect-icon {
    right: 20px !important;
  }
}

.interview-filters {
  // position: sticky;
  top: 65px;
  overflow: visible;
  // z-index: 999999;
  background: #f7f7f7;
  left: 0;
  padding: 20px 10px 10px 0px;
}

.survey-row {
  .MuiTableCell-root {
    border-bottom: none !important;
  }
}

.generate-ai {
  height: 230px;
  overflow-y: scroll;
}

.button_style {
  background: unset !important;
  border: none !important;
}

.menuItem {
  color: #707070 !important;
}

.project_setting_btn {
  color: #707070 !important;
  font-weight: 500 !important;

  &:hover {
    color: #0052cc !important;
  }
}

.btn_active {
  a {
    color: #0052cc !important;
  }
}

.button_padding {
  padding: 3px 12px !important;
}

.filter_wrapper .css-18nc3u2 .MuiOutlinedInput-root {
  border-radius: 10px !important;
  padding: 1px 9px !important;
  font-size: 14px !important;
}

.f-600 {
  font-weight: 600 !important;
}

.w-48 {
  width: 48%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-65 {
  width: 65%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-95 {
  width: 95%;
}

.w-30px {
  width: 30px;
}

.w-40px {
  width: 40px;
}

.mw_200 {
  max-width: 200px !important;
}

.w-180 {
  width: 180px !important;
}

.loading_heading_long {
  width: 150px !important;
}

.pricing_card_border {
  border: 1px solid lightgray;
  border-radius: 10px;
  height: 484px !important;
  width: 291px !important;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 28px;
}

.text_loader {
  height: 15px;
  width: 80px;
  margin-top: 6px;
}

.h_26 {
  height: 26px !important;
}

.h_47 {
  height: 47px !important;
}

.name_loader {
  height: 12px;
  width: 80px;
}

.loader_buttons {
  height: 38px;
  width: 120px;
}

.shimmerImg {
  filter: grayscale(100%) invert(98%) brightness(95%) sepia(15%) !important;
}

.loading_heading_short {
  width: 80px !important;
}

.hw_50px {
  width: 50px;
  height: 50px;
}

.h_40 {
  height: 40px !important;
}

.w-120 {
  width: 120px !important;
}

.box-s-n {
  box-shadow: none !important;
}

.height-570 {
  height: 570px !important;
}

.custom_multiselect {
  .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 12px !important;
  }
}

.item-heading {
  .remove_limit {
    max-width: unset !important;
  }
}

.verify_email_box {
  min-height: calc(100vh - 260px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.verify_card {
  margin: auto !important;
}

.uni_search_close_icon {
  padding-left: 14px !important;
  right: 60px !important;
  color: rgb(128, 126, 163) !important;
}

.p-relative {
  position: relative;
}

.profile_camera_icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(85, 118, 148, 0.5);
  border-radius: 10px;
  position: absolute;
  top: 0;
}

.aa-Form:focus-within {
  border-color: rgba(0, 84, 203, 1) !important;
  box-shadow: rgba(0, 84, 203, 0.2) 0 0 0 2px,
    inset rgba(0, 84, 203, 0.2) 0 0 0 2px !important;
  outline: medium none currentColor !important;
}

.dashboard-task-card {
  padding: 14px 10px !important;
}

.text {
  position: relative;

  .task_time_ago {
    color: rgba(157, 166, 186, 0.7);
    font-size: 10px;
    left: 30px;
    top: 18px;
    position: absolute;
  }

  .sprint_completed_time {
    font-size: 10px;
    position: absolute;
    left: 0px;
    top: 25px;
  }
}

.p-12 {
  padding: 12px !important;
}

.user_sprint_section {
  height: 337px;
  padding-right: 10px;

  .text-div {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;

    @media (max-width: 912px) {
      flex-direction: column;
    }
  }
}

.add_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 337px !important;
  height: unset;
}

.add_scroll::-webkit-scrollbar {
  width: 4px;
}

.add_scroll::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

.add_scroll::-webkit-scrollbar-track {
  background: #d8d9db;
}

.over_flow_wrap {
  white-space: "initial";
  color: "#000000";
  overflow-wrap: anywhere;
}

.filter_drawer_btn {
  padding: 11px 10px !important;
}

.filter_drawer_btn-gobal {
  padding: 6px 10px;
  border: 1px solid #707070;
}

.pw-100 {
  width: 100% !important;
}

.w-220 {
  width: 220px !important;
}

.para_loader {
  width: 120px;
  height: 14px;
}

.progress_shimmer {
  width: 190px;
  height: 14px;
}

.add_task_shimmer_btn {
  height: 20px;
  width: 60px;
}

.shimmer-progress-heading {
  width: 100% !important;

  .section2 {
    width: 100% !important;
    display: flex;
    align-items: center;

    .progress_bar {
      width: 190px;
      height: 14px;
    }

    .add_task_shimmer_btn {
      height: 20px;
      width: 60px;
    }

    .div1 {
      height: 36px;
      width: 36px;
    }
  }
}

.shimmer-kanban {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // gap: 21px;
  .main-kanban {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .kanban {
    width: 23.5%;
    height: 500px;

    .box-head {
      margin-left: 3px !important;
      width: 232px !important;

      .section2 {
        width: 100% !important;
        gap: unset !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
      }
    }

    .box-body {
      .main-div {
        flex-direction: column !important;
        height: 85px !important;
        width: 230px !important;

        .section1 {
          width: 100%;
        }

        .section2 {
          display: flex !important;
          flex-direction: column !important;
          margin-top: 10px !important;
          width: 100% !important;

          .div3 {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.pw-100 {
  width: 100%;
}

.history_description {
  // font-family: "Poppins", Medium !important;
}

.task_items_list {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      padding: unset !important;
    }
  }
}

.table_content {
  border-radius: 10px !important;
  border: 1px solid #eee !important;

  .bs_none {
    box-shadow: unset !important;
  }

  .b_bottom {
    border-bottom: 1px solid #eee !important;
  }

  .br-0 {
    border-radius: 0px !important;
  }

  .top_side_radius {
    border-radius: 10px 10px 0px 0px !important;
  }
}

.project-footer .react-datepicker-wrapper .react-datepicker__input-container {
  padding: unset !important;
}

// */
// table view
// */
.pagination {
  .css-4muqlg-MuiGrid-root>.MuiGrid-item {
    padding-top: unset !important;
  }
}

.pagination {
  cursor: pointer;
  float: right;
  margin-right: -10px;

  .pre {
    background: #f4f5f7;
    border-radius: 10px !important;
    border: none;
    color: #111;
  }

  .inner_item {
    display: flex;
    background: #f4f5f7;
    border-radius: 10px;

    .page-link {
      background: #f4f5f7;
      border-radius: 10px;
      border: none;
      color: #111;

      &.active {
        background: #0052cc;
        color: #fff;
      }
    }
  }
}

.MuiPagination-ul {
  @include paginationSelected();
}

.css-2gftvx {
  @include paginationSelected();
}

.view_icon_div {
  .view-icon {
    width: 15px !important;

    &:hover {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }
  }

  .selected {
    filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
  }
}

.task_table_head th {
  background-color: #f4f5f7;
  padding: 10px;

  @media (max-width: 990px) {
    min-width: 136px;
  }
}

.team_table_head th {
  background-color: #f4f5f7;
  padding: 10px;

  @media (max-width: 990px) {
    min-width: 136px;
  }
}

// .task-content-table {
//   @media (max-width: 992px) {
//     min-width: 860px !important;
//     // overflow: auto !important;
//   }
// }

.task_table_head th:first-child {
  min-width: 400px !important;
  padding-left: 10px;

  @media (max-width: 992px) {
    min-width: 287px !important;
    // overflow: auto;
  }
}

.team_table_head th:first-child {
  @media (max-width: 992px) {
    min-width: 287px !important;
    // overflow: auto;
  }
}

.task-table {
  font-weight: 500;

  .text {
    min-height: 48px;
  }

  .subtask_name_td {
    width: 90% !important;
    margin-left: auto;
  }

  .task_save_input_row {
    border: 2px solid #0052cc !important;
    border-radius: 10px !important;
    padding: unset !important;
  }

  .task_save_btn {
    border-radius: 6px;
    padding: 3px 20px;
    background-color: #0052cc;
    color: #fff;
  }

  .hg-priority {
    background: rgb(255, 240, 237);
    color: #ff7452;
  }

  .md-priority {
    background: #fff6e5;
    color: #ffab00;
  }

  .low-priority {
    background: #e9f2ff;
    color: #2684ff;
  }

  .none-priority {
    background: #eee;
    color: #7a7a7a;
  }

  .date {
    background: #e9f2ff;
    color: #2684ff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;

    .react-datepicker__input-container {
      padding: 5px;

      svg {
        font-size: 14px;
      }
    }
  }

  .task_table_action {
    background: #f4f5f7;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    width: 27px;
    margin: auto;

    svg {
      width: 3px;
    }

    &:hover {
      svg {
        color: #0052cc;
      }
    }

    .nav-select .dropdown-menu.show {
      top: 12px;
    }

    .show.dropdown {
      .option-icon.viewtask-dropdown {
        background: #e5edfa !important;

        button svg {
          color: #0052cc !important;
        }
      }
    }

    .option-icon {
      width: unset;
      height: unset;

      a {
        color: #707070;
        font-size: 14px;
        font-weight: 500;

        &:active {
          color: #707070 !important;
          background: unset !important;
        }
      }
    }

    .dropdown {
      width: 100%;
      height: 100%;

      .dropdown_menu_viewtask {
        inset: 0px 0px auto auto !important;
        top: 28px !important;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
        border-radius: 10px !important;
        border: none !important;
        min-width: 7rem;
        transform: none !important;
      }

      button {
        width: 100%;
        height: 100%;
      }
    }
  }

  .newTask_input_section {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 14px;
      color: #0052cc;
    }

    input {
      border: none;
      width: 70%;
      outline: none;
    }

    svg {
      font-size: 18px !important;
    }

    .task_input_close {
      font-size: 24px !important;
      color: #7a7a7a !important;
    }
  }

  .pb-17 {
    padding-bottom: 17px !important;
  }

  .table_story_point {
    display: flex;
    flex-direction: column;
  }

  .table_story_point_bg {
    background: #f4f5f7;
    width: 30px;
    height: 28px;
    margin: auto;
  }

  .task_table_title {
    min-width: 400px !important;
  }

  .table_title_icon_div {
    display: flex !important;
  }
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.userStory {
  background-image: url("../images/userStory.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.task-done-blue {
  background-image: url("../images/tasks.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.test-case-icon {
  background-image: url("../images/test-cases.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.task-done-yellow {
  background-image: url("../images/task-yellow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.bug {
  background-image: url("../images/Bug.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pl-5 {
  padding-left: 5px !important;
}

.h-120 {
  height: 120px !important;
}

.h-60 {
  height: 60px !important;
}

.bg-white {
  background: #fff;
}

.illustration_box {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;

  img {
    margin: 20px;
  }

  button {
    width: fit-content;
  }

  .illustration_empty_text {
    font-size: 16px !important;
    font-weight: 500;
    color: #5c6378 !important;
    line-height: 24px !important;
  }
}

.w-40 {
  width: 40% !important;
}

.latest_realeas_box {
  min-height: 415px;
}

.mx-170 {
  max-width: 170px !important;
}

.sub_task_points {
  background: #eee;
  font-size: 11px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.subtask_dropdown {
  background: #eee;
  border-radius: 6px;
}

.w-23 {
  width: 23% !important;
}

.subtask_assignee {
  height: 26px !important;
  width: 26px !important;
  border-radius: 6px !important;
}

.user_story_btn {
  background-color: #009a3f;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.user_bug_btn {
  background-color: #f0142f;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.user_testCase_btn {
  background-color: #085394;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.recording_btn {
  padding: 10px 12px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px 9px !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.react-datepicker__input-container input {
  &::placeholder {
    color: #707070;
  }
}

.hw-20 {
  height: 20px;
  width: 20px;
}

.left89 {
  left: 89% !important;
}

.select_closeIcon {
  color: #707070 !important;
  padding: 3px !important;
  height: 27px;
  width: 27px;
}

.left76 {
  left: 76% !important;
}

.select_closeIcon_bg {
  background-color: #f0f0f0 !important;
  border-radius: 50% !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.line_through {
  text-decoration: line-through;
  color: #707070;
}

.sprint_report_tabContent {
  min-height: 594px !important;

  .add_scroll {
    padding-right: 10px;
    max-height: 650px !important;
  }
}

.sprintReportTabs {
  .nav-item {
    .nav-link {
      margin-right: 18px !important;
    }
  }
}

.sprint_chart_text {
  border-bottom: 2px solid #e4e7ec;
}

.no_report_message {}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.p-18px {
  padding: 18px;
}

.modal-open {
  overflow: auto !important;
  padding-right: unset !important;
}

.project_progress {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .custom_progressBar {
    height: 5px;
    display: flex;
    width: 90%;
    background: rgba(112, 112, 112, 0.3);
  }

  .complete_percent {
    font-size: 10px;
    color: #707070;
  }
}

.w_unset {
  width: unset !important;
}

.h_unset {
  height: unset !important;
}

.color_unset {
  color: unset !important;
}

.h-90 {
  height: 90% !important;
}

.h-87 {
  height: 87% !important;
}

.project_files {
  .file_box_col {
    @media (max-width: 1100px) {
      width: 33.33% !important;
    }

    @media (max-width: 800px) {
      width: 50% !important;
    }

    @media (max-width: 596px) {
      width: 100% !important;
    }

    .file_box {
      box-shadow: 0px 1px 4px #0000001a;
      border-radius: 10px;

      .file_icon {
        width: 25%;

        @media (max-width: 596px) {
          width: 15%;
        }
      }

      .thumbnailBg {
        background: rgba(205, 205, 205, 0.3);
      }

      .file_thumbnail {
        height: 150px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .file_icon_load {
        height: 52px;
      }

      .file_details {
        width: 75%;

        @media (max-width: 596px) {
          width: 85%;
        }

        .file_name_load {
          height: 16px;
        }

        .file_name {
          @media (max-width: 800px) {
            font-size: 14px;
          }

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        .record-file-actions {
          .transcribeIcon {
            height: 12px;
            width: 14px;
          }

          img {
            cursor: pointer;
          }
        }

        .userStory {
          @media (max-width: 992px) {
            width: 16px !important;
          }
        }

        .file_origin {
          height: 16px;
          width: 16px;
          margin-right: 10px;
        }

        .file_time_load {
          height: 16px;
          width: 40%;
        }

        .file_action_load {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.add_file_btn_div {
  width: fit-content !important;
  margin-left: auto;

  @media (max-width: 680px) {
    margin: 20px 0px 10px !important;
  }
}

.cursor_default {
  cursor: default !important;
}

.mt-1px {
  margin-top: 1px !important;
}

.span_border {
  color: #707070;
}

.max_height_unset {
  max-height: unset !important;
}

.option-icon {
  .dropdown-menu {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1607843137);
    border-radius: 10px !important;
    border: none !important;
    min-width: 7rem;
    inset: 0px 0px auto auto !important;
    top: 30px !important;
    transform: none !important;
  }
}

.copyIcon {
  &:hover {
    color: #0052cc;
  }
}

.plan-shoutMessage {
  width: fit-content;
  margin: 30px auto;
}

.sub-task-drawer .MuiDrawer-root {
  transition: transform 0.8s ease !important;
}

.sub-task-drawer .MuiDrawer-paper {
  transition: transform 0.8s ease !important;
}

.IconHover {
  filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
}

.ml-minus10 {
  margin-left: -10px !important;
}

.ml-minus5 {
  margin-left: -5px !important;
}

.ml-minus6 {
  margin-left: -6px !important;
}

.select-all-user-box {
  border-bottom: 1px solid #7a7a7a;

}

.user-search-box {
  border-bottom: 1px solid #7a7a7a;
  padding: 10px 10px 5px 10px;

  .user-search-field-wrap {
    border-bottom: 2px solid #0052cc;
    display: flex;
    align-items: center;
    min-width: 220px;

    svg {
      font-size: 1.25rem !important;
    }

    .custom-user-search {
      border: none !important;
      outline: none !important;
      font-size: 14px;
      box-shadow: none !important;
      width: 100%;
    }

  }
}

.custom-menu-box {
  max-height: 250px !important;
  overflow: auto;
}

.report-view-select {
  border: none;
  border-radius: 6px;
  font-size: 12px;
  padding: 5px;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px !important;
  cursor: pointer;
}

.html-content-custom-style {
  img {
    cursor: pointer;
  }

  pre {
    background: #f1f1f1;
    border-radius: 6px;
    padding: 10px 10px 20px;
    margin: 10px 0px;
    position: relative;
    font-family: monospace !important;
    white-space: pre-wrap !important;

    .copy-button {
      right: 10px;
      border: none !important;

      .checked-icon {
        color: #28745A;
      }

      .copy-icon {
        color: #7a7a7a;

      }

      &:focus,
      &:focus-visible,
      &:active {
        border: none !important;
        outline: none !important;
      }


      .copy-btn-icon,
      .copy-btn-text {
        transition: all .3s ease-in-out;
      }
    }
  }
}

.card-detail-input {
  font-size: 16px;
  color: #424770;
  letter-spacing: 0.025em;
  font-family: Source Code Pro, monospace !important;
  padding: 10px 10px 10px 15px !important;

  &::placeholder {
    color: #aab7c4;
  }

  .initial-input-value {
    color: #9e2146;
  }
}

.task-drawer-control-btn {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;

  .disable-control-btn {
    cursor: default !important;
    opacity: .5;
  }

  .default-control-btn {
    cursor: pointer !important;
  }
}

.copy-link {
  margin-left: 8px;
  cursor: pointer;
  color: #9b9b9b;
  width: 14px;
  height: 14px;

  &:hover {
    filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.task-card-label {
  padding: 5px 8px !important;
  background: #fff7e6 !important;
  border-radius: 6px !important;
  color: #ffab00 !important;
}

.task-story-point-icon-div {
  span {
    color: #707070;
  }
}

.add-icon-btn {
  background: #0052cc;
  height: 28px;
  width: 28px;
}

.label-input-border-less {
  border-bottom: 1px solid #707070 !important;

  &:focus {
    border-bottom: 1px solid #0052cc !important;
    outline: none !important;
  }
}

.add-label-button {
  background: #0052cc;
  padding: 4px 8px;
}

.project-selector {
  border-radius: 6px;
  border: 1px solid #707070;
  padding: 6px;
  width: 225px;
  font-size: 14px;

  &:focus-within,
  &:focus {
    outline: none;
  }
}

.custom-popover-menu-search {
  padding: 15px 15px 8px !important;
}

.custom-popover-menu-select-all {
  padding: 4px 15px !important;
}

.pe-17 {
  padding-right: 17px !important;
}

.custom-popover-menu-item {
  padding: 4px 0px !important;
}

.custom-menu-option-box {
  max-height: 300px;
  min-width: 300px;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.select-project-filter-btn {
  padding: 6.5px 8px;
  border: 1px solid #707070;
  background: transparent;
  font-size: 14px;
  color: #707070;
  border-radius: 6px;

  &:active,
  &:focus {
    outline: none;
  }

  .filter-count {
    color: #fff;
    font-size: 12px;
    background: #0052cc;
    width: 20px;
    height: 20px;
  }
}

.add-item-options {
  padding: 6px;
  background-color: transparent;
  /* Default background color */

  &:hover {
    background: #fff;
    /* Change background color on hover */

    .add-item-icon {
      opacity: 1 !important;
      /* Make icons visible on hover */
    }
  }

  .task-icon {
    background-image: url("../images/tasks.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .add-item-icon {
    height: 24px !important;
    width: 24px !important;
    opacity: 0 !important;
    /* Hidden by default */
    transition: opacity 0.4s ease;
    /* Smooth transition */
  }
}

.public-DraftStyleDefault-block {
  margin: 5px 0px !important;
}



.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensures it stays on top */
}

.start-recording-countBtn {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background-color: rgba($color: #0052cc, $alpha: .8);
  font-size: 64px;
}

.media-control-menu {

  .time-counter {
    min-width: 48px;
  }

  button {
    padding: 6px 15px;
  }

  .drawing-color-picker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 9999999999 !important
  }

  .drawing-controls {
    transform: translateX(20px);
    opacity: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out, max-width 0.2s ease-in-out, max-height 0.2s ease-in-out;
  }

  .drawing-controls.show {
    transform: translateX(0);
    opacity: 1;
    max-width: unset !important;
    /* Adjust based on your content */
    max-height: 100px !important;
  }

  .drawing-controls.hide {
    transform: translateX(20px);
    opacity: 0;
    max-width: 0;
    max-height: 0;
  }


}

.control-menu-fixed {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #e5edfa !important;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1019607843);
  z-index: 999999;
  /* Ensures the menu stays on top of other elements */
}

.countdown-number {
  animation: fade-in-out 1s ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.pointer-none {
  pointer-events: none !important;
}

.pointer-auto {
  pointer-events: auto !important;
}

.screen-recording-overlay {
  .drawing-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 30px);
    overflow: hidden;
    height: 100vh;
    z-index: 99999;
    background: transparent;
  }
}

.MuiPopper-root.MuiTooltip-popper {
  z-index: 999999999 !important;
}