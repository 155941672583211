// @import '../../assets/scss/bootstrap.scss';

// body {
//     font-family: 'Poppins', sans-serif !important;
// }

// .is-invalid:placeholder{
//     color: red;
// }
.custom-font-size-14 {
  font-size: 14px;

  a {
    text-decoration: none;
  }
}

.custom-font-size-12 {
  font-size: 12px;
}

.hover__black {
  &:hover {
    color: black;
  }
}

.alignment {
  display: flex;
  height: 65vh;
  align-items: center;
  justify-content: center;
}

.login__card-2 {
  height: 100% !important;
  width: 410px;
  background: #fff;
  margin: auto;
  border-radius: 10px;
  padding: 30px;
  box-shadow: -20px 20px 1px 0px rgb(242 242 242 / 75%);

  @media (max-width: 498px) {
    width: 100%;
  }
}

.login__wrapper {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;

  // need to remove
  // @media (min-width: 412px) and (max-width: 498px) {
  //   height: 100vh !important;
  // }

  // @media (min-width: 1540px) {
  //   height: 100vh !important;
  // }

  // @media (min-height: 720px) {
  //   padding-bottom: 196px !important;
  // }

  // @media (max-width: 510px) {
  //   padding-bottom: 175px !important;
  // }



  .login__card {
    height: 100%;
    width: 410px;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    padding: 30px;
    margin-top: 15px;
    box-shadow: -20px 20px 1px 0px rgb(242 242 242 / 75%);

    @media (max-width: 498px) {
      width: 100% !important;
      padding: 20px;
    }

    .form {
      label {
        font-weight: 500;
      }

      .password_input {
        position: relative;

        .fa-eye {
          position: absolute;
          right: 14px;
          top: 47px;
          color: #707070;
          cursor: pointer;
        }

        .fa-eye-slash {
          position: absolute;
          right: 14px;
          top: 47px;
          color: #707070;
          cursor: pointer;
        }
      }
    }
  }

  .login__social-media {
    // height: 40px;
    // width: 100%; need to remove
    padding: 10px;
    background: #f5f5f5;
    border-radius: 10px;
    padding-top: 6px;

    a {
      font-weight: 500;
    }
  }

  .form {
    .custom-image-margin {
      margin-right: 19px;
    }

    .with__facebook {
      height: 21px;
      margin-left: 14px;
    }
  }

  .login_navbar_section {
    width: 100%;
    padding: 30px 0px 40px 0px;

    .btn-back {
      background: #f4f5f7;
      color: #0052cc;
      font-size: 14px;
      padding: 12px 32px;
      font-weight: 600;
      cursor: pointer;

      a {
        text-decoration: none;
        color: unset;
        background: unset;
      }

      &:hover,
      &:focus {
        background: #f4f5f7;
        color: #0052cc;
      }
    }

    .navbar-brand {
      img {
        margin-top: -8px;
      }
    }
  }


}



@media (min-width: 1540px) {
  .signup_pages {
    height: 100% !important;
  }
}

.password-toggle-button {
  display: contents;
  border: none;
  outline: none;
}

// .outletPages {
//   display: flex;
//   justify-content: center;
//   // align-items: center;
// }