.view_button {
    border-radius: 10px !important;
    padding: 9px 14px;

    &:active {
      outline: none !important;
    }

    &:hover {
      color: #2684ff !important;
      background-color: #e6f2ff !important;
    }
  }
  .view_active {
    color: #2684ff !important;
    background-color: #e6f2ff !important;
    border: 1px solid #e6f2ff !important;
  }

  .buttons button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
  }
  .gantt_grid_head_add {
    display: none !important;
  }
  .gantt_add  {
    display: none !important;
  }